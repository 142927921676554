import { ReactNode, useState } from 'react';

type Props = {
  text: string;
  children?: ReactNode;
  containerClassName?: string
}

export const EliminationTeamsTooltip: React.FC<Props> = ({ text, children, containerClassName }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  let classForContainer = 'elimination-teams-tooltip-container';

  if (containerClassName) {
    classForContainer += ` ${containerClassName}`;
  }

  return (
    <div
      className={classForContainer}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && <div className="elimination-teams-tooltip-text">{text}</div>}
    </div>
  );
};
