import { get, post } from "../services/Api";
import * as types from "./types";
import * as teamTypes from "./teamTypes";
import history from "./../../routes/history";
import store from "../store";
import { CreateOrderRequestBodyData } from "types";
import { updatePromoBalance } from "./AppAction";
import { League } from "components/Common/LeagueHeader/LeagueHeader";
import { TrophyBeforePurchase } from "components/PurchaseTrophy/types";
import { ContinueSignUp } from "components/Common/SignUpStepFormModal/types";
import { User } from "types/user";
import { notification } from "antd";

export const updateData = () => {
  const token: string = localStorage.getItem("token") || "";
  return async (dispatch: any, getState: any) => {
    try {
      const res: any = await get("/user/data");
      dispatch({
        type: types.SET_DATA,
        payload: { token, resData: res.message },
      });
    } catch (error: any) {
      console.log(error);
      if (
        error.response?.data.code === "401" ||
        error.response?.data.code === "404"
      ) {
        dispatch(logout());
      }
    }
  };
};

export const setData = (token: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: types.SET_DATA_REQUEST,
      payload: {},
    });
    const res: any = await get("/user/data");
    dispatch({
      type: types.SET_DATA,
      payload: { token, resData: res.message },
    });
  } catch (error: any) {
    console.log(error);
    if (
      error.response?.data.code === "401" ||
      error.response?.data.code === "404"
    ) {
      dispatch(logout());
    }
  }
};

export const loginRequest = ({
  userName,
  password,
  isNewUser = false,
  rememberMe = false
}: {
  userName: string;
  password: string;
  isNewUser?: boolean;
  rememberMe?: boolean;
}) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: types.LOGIN_REQUEST,
        payload: {},
      });
      const res: any = await post("/login", { userName, password, rememberMe });

      localStorage.setItem("token", res.data);
      localStorage.setItem("userId", res.id);

      dispatch({
        type: types.LOGIN_SUCCESS,
        payload: { token: res.data, resData: res },
      });

      const resTeam: any = await get(`/user/get-avatar-list/${res.id}`);
      dispatch({
        type: teamTypes.USERTEAMSLIST_SUCCESS,
        payload: { userTeamsList: resTeam.data },
      });

      if (resTeam?.data.length > 0) {
        if (res.birthDate == null) {
          history.push("/signup");
        } else {
          history.push("/ownfranchise");
        }
      } else {
        if (res.birthDate == null) {
          history.push("/signup");
        } else if (isNewUser) {
          history.push("/purchasefranchise");
        } else {
          history.push("/nofranchise");
        }
      }
    } catch (error: any) {
      dispatch({
        type: types.LOGIN_FAIL,
        error: error.response?.data.error,
      });
    }
  };
};

export const logout = () => {
  return async (dispatch: any) => {
    try {
      // @TODO Why not clear all?
      localStorage.removeItem("token");
      localStorage.removeItem("gameEngineToken");
      const URL = window?.location?.href?.split("/")[3];
      localStorage.setItem("UserData", URL);
      dispatch({
        type: types.LOGOUT,
        payload: {
          success: URL,
        },
      });
      history.push("/");
    } catch (error) {
      console.log(error);
    }
  };
};

export const signupAndLogin = ({
  userName,
  email,
  password,
  metaMask,
  birthDate,
  country,
  inviteCode,
  inviteType,
  img
}: {
  userName: string;
  email: string;
  password: string;
  metaMask: string | boolean | null;
  birthDate?: string;
  country?: string;
  inviteCode?: string;
  inviteType: string;
  img?: string; // image link from google oauth
}) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: types.SIGNUP_REQUEST,
        payload: {},
      });

      const res = (await post("/user", {
        userName,
        email,
        password,
        metaMask,
        birthDate,
        country,
        inviteCode,
        inviteType,
        img
      })) as { message: string; inviteCredits?: number };

      dispatch({
        type: types.SIGNUP_SUCCESS,
        payload: res.inviteCredits || null,
      });

      store.dispatch(loginRequest({ userName, password, isNewUser: true, rememberMe: false}));
    } catch (error: any) {
      dispatch({
        type: types.SIGNUP_FAIL,
        error: error.response?.data.error,
      });
    }
  };
};

export const signup = (
  userName: string,
  email: string,
  password: string,
  metaMask: string | boolean | null,
  birthDate?: string,
  country?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: types.SIGNUP_REQUEST,
        payload: {},
      });
      // const res: any =
      const res = (await post("/user", {
        userName,
        email,
        password,
        metaMask,
        birthDate,
        country,
      })) as { message: string; inviteCredits?: number };

      dispatch({
        type: types.SIGNUP_SUCCESS,
        payload: { inviteCredits: res.inviteCredits },
      });
      // dispatch();
    } catch (error: any) {
      dispatch({
        type: types.SIGNUP_FAIL,
        error: "SOMETHING WENT WRONG",
      });
    }
  };
};

type UniquenessCheckResponse = {
  success: boolean;
  message?: string;
};

export const checkEmailOrUsernameAvailable = async (
  userNameOrEmail: string
) => {
  try {
    const res: UniquenessCheckResponse = await post(
      "/user/check-if-userName-or-email-available",
      {
        userNameOrEmail,
      }
    );
    return res;
  } catch (error: any) {
    console.log(error);
    throw error.response.data.error;
  }
};

export const resendVarityEmail = (userName: string) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: types.RESENDVARITYEMAIL_REQUEST,
        payload: {},
      });
      const res: any = await post("/resend-verify-email", {
        userName,
      });
      dispatch({
        type: types.RESENDVARITYEMAIL_SUCCESS,
        payload: {
          success: res.message,
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.RESENDVARITYEMAIL_FAIL,
        error: error.response.data.error,
      });
    }
  };
};

export const forgotPasswordEmail = (userName: string) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: types.FORGOTPASSWORDEMAIL_REQUEST,
        payload: {},
      });
      const res: any = await post("/forgot-password-email", {
        userName,
      });
      dispatch({
        type: types.FORGOTPASSWORDEMAIL_SUCCESS,
        payload: {
          success: res.message,
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.FORGOTPASSWORDEMAIL_FAIL,
        error: error.response?.data.error,
      });
    }
  };
};

export const resetPassword = (token: string, password: string) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: types.RESERTPASSWORD_REQUEST,
        payload: {},
      });
      const res: any = await post("/reset-password", { token, password });

      dispatch({
        type: types.RESERTPASSWORD_SUCCESS,
        payload: {
          success: res.message,
        },
      });

      setTimeout(() => {
        history.push("/");
      }, 1000);
    } catch (error: any) {
      dispatch({
        type: types.RESERTPASSWORD_FAIL,
        error: error.response.data.error,
      });
    }
  };
};

export const metaMaskLoginRequest = (metaMask: any, rememberMe = false) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: types.METAMASKLOGIN_REQUEST,
        payload: {},
      });
      const res: any = await post("/loginMetamask", { metaMask, rememberMe });
      localStorage.setItem("token", res.data);

      dispatch({
        type: types.METAMASKLOGIN_SUCCESS,
        payload: { token: res.data, resData: res },
      });

      const resTeam: any = await get(`/user/get-avatar-list/${res.id}`);
      dispatch({
        type: teamTypes.USERTEAMSLIST_SUCCESS,
        payload: { userTeamsList: resTeam.data },
      });
      const UserSecondLink: any = localStorage?.getItem("UserData");
      const condition = UserSecondLink?.split("?")[0];
      if (resTeam?.data.length > 0) {
        condition === "league"
          ? history.push(`/${UserSecondLink}`)
          : history.push("/ownfranchise");
        localStorage.removeItem("state");
      } else {
        history.push("/nofranchise");
      }
    } catch (error: any) {
      console.log("error", error);
      if (error.response?.data.error === "need to complete your profile") {
        dispatch({
          type: types.METAMASKLOGIN_FAIL,
          error: error.response?.data.error,
          payload: { metaMaskData: metaMask },
        });
        history.push("/signup");
      }
      dispatch({
        type: types.METAMASKLOGIN_FAIL,
        error: error.response?.data.error,
        payload: { metaMaskData: metaMask },
      });
    }
  };
};

export const connectMetaMaskRequest = (metaMask: any) => {
  return async (dispatch: any, getState: any) => {
    const userName = getState().app.resData?.userName;
    try {
      dispatch({
        type: types.CONNECTMETAMASK_REQUEST,
        payload: {},
      });
      const res: any = await post("/user/connect-metamask", {
        metaMask,
        userName,
      });

      dispatch({
        type: types.CONNECTMETAMASK_SUCCESS,
        payload: { metaMaskData: res },
      });
      
      notification.success({
        message: "MetaMask Connected",
        description: "MetaMask wallet connected/updated successfully",
        placement: "bottomRight",
      })

      dispatch(updateData());
    } catch (error: any) {
      console.log("connect metaMask error", error);
      dispatch({
        type: types.CONNECTMETAMASK_FAIL,
        error: error.response?.data.error,
      });
      notification.error({
        message: "MetaMask Connection Error",
        placement: "bottomRight",
      })
    }
  };
};

type CreateOrderType = {
  amount: string,
  fee: string,
  promoCredits: number,
  status: string,
  oderId: string,
  transactionId: string,
  emailAddress: string,
  ether: string,
  partnerCode: string,
  paymentType: string,
  metamaskAccount: string | null,
  city: any,
  orderType: string,
  gid?: number,
  type?: League["type"],
  trophy?: TrophyBeforePurchase
}

export const createOrder = ({
  amount,
  fee,
  promoCredits,
  status,
  oderId,
  transactionId,
  emailAddress,
  ether,
  partnerCode,
  paymentType,
  metamaskAccount,
  city,
  orderType,
  gid,
  type,
  trophy
}: CreateOrderType) => {
  return async (dispatch: any, getState: any) => {
    const userId = getState().app.resData?.id;
    const userName = getState().app.resData?.userName;
    const walletBalance = getState().app.resData?.walletBalance;
    const bodyData: CreateOrderRequestBodyData = {
      userId,
      userName,
      amount,
      fee,
      promoCredits,
      status,
      oderId,
      transactionId,
      emailAddress,
      ether,
      partnerCode,
      paymentType,
      metamaskAccount,
      city,
      orderType,
      walletBalance,
    };

    if (gid && type) {
      bodyData.gid = gid;
      bodyData.type = type;
    }

    console.log("trophy", trophy);
    
    if (trophy) {
      bodyData.trophyData = trophy;
    }

    try {
      dispatch({
        type: types.CREATEPAYPALODER_REQUEST,
        payload: {},
      });
      const res: any = await post("/user/process-purchase", bodyData);

      if (orderType === "purchaseFranchise") {
        localStorage.setItem("newFrenchiseId", res?.message);
        dispatch({
          type: types.CREATEPAYPALODER_SUCCESS,
          payload: {},
        });
        history.push("/customize-franchise/" + res?.message);
      } else if (
        orderType === "leaguePayment" 
        || orderType === "leaguePayment_wallet" 
        || orderType === "trophyPurchase" 
        || orderType === "purchaseTrophy_wallet"
      ) {
        dispatch({
          type: types.CREATEPAYPALODER_SUCCESS,
          payload: {
            transactionsStatus: res,
            updatedWalletBalance: res?.updatedWalletBalance,
          },
        });
        if (
          res &&
          res.updatedPromoBalance !== undefined &&
          res.updatedPromoBalance !== null
        ) {
          dispatch(updatePromoBalance(res.updatedPromoBalance));
        }
      }

      if (orderType === "trophyPurchase" || orderType === "purchaseTrophy_wallet") {
        history.push("/league");
      }
    } catch (error: any) {
      dispatch({
        type: types.CREATEPAYPALODER_FAIL,
        error: error,
      });
    }
  };
};

export const clearTransactionStatus = () => {
  return async (dispatch: any) => {
    dispatch({
      type: types.CLEAR_TRANSACTION_STATUS,
    });
  };
}

export const sendContactUsEmail = (
  firstName: any,
  lastName: any,
  email: any,
  reason: any,
  message: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: types.SENDCONTACTUSEMAIL_REQUEST,
        payload: {},
      });
      const res: any = await post("/contact-us-email", {
        firstName,
        lastName,
        email,
        reason,
        message,
      });
      dispatch({
        type: types.SENDCONTACTUSEMAIL_SUCCESS,
        payload: {
          success: res.message,
        },
      });
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: types.SENDCONTACTUSEMAIL_FAIL,
        error: error.response.data.error,
      });
    }
  };
};

export const updateUserProfile = (data: any) => {
  const token: string = localStorage.getItem("token") || "";
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.UPDATE_USER_REQUEST,
        payload: {},
      });
      const res: any = await post("/update-user-info", data);
      dispatch({
        type: types.UPDATE_USER_SUCCESS,
        payload: {
          success: res.message,
        },
      });
      dispatch(setData(token));
    } catch (error: any) {
      dispatch({
        type: types.UPDATE_USER_FAIL,
        error: error.response.data.error,
      });
    }
  };
};

export const getTranscations = (data: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.GET_USER_TRANSACTIONS_REQUEST,
        payload: {},
      });
      const res: any = await get(`/user/get-transcations/${data}`);
      dispatch({
        type: types.GET_USER_TRANSACTIONS_SUCCESS,
        payload: {
          success: res.message,
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_USER_TRANSACTIONS_FAIL,
        error: error?.response?.data?.error,
      });
    }
  };
};

export const sendEmailInvitation = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: types.USER_MAIL_REQUEST,
        payload: {},
      });
      const res: any = await post("/user/send-Email-Notification", data);
      dispatch({
        type: types.USER_MAIL_SUCCESS,
        payload: {
          success: res.message,
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.USER_MAIL_FAIL,
        error: error?.message?.includes("404")
          ? "User(s) not found or they turned off invite notifications"
          : error,
      });
    }
  };
};

export const cleanInviteEmailError = () => {
  return async (dispatch: any) => {
    dispatch({
      type: types.CLEAN_INVITE_EMAIL,
      payload: {},
    });
  };
};

export const updateUserTutorialDisabled = (data: {
  userId: number;
  disabled: boolean;
}) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: types.UPDATE_USER_TUTORIAL_DISABLED_REQUEST,
        payload: {},
      });
      const res: any = await post("/user/update-user-tutorial-disabled", data);
      dispatch({
        type: types.UPDATE_USER_TUTORIAL_DISABLED_SUCCESS,
        payload: {
          tutorialDisabled: res.value,
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.UPDATE_USER_TUTORIAL_DISABLED_FAIL,
        error: error?.message?.includes("404") ? "User not found" : error,
      });
    }
  };
};


export const loginGoogle = (token: string, rememberMe = false) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: types.LOGIN_REQUEST,
        payload: {},
      });
      post<(User & { data: string }) | ContinueSignUp>(`/loginGoogle`, { token, rememberMe })
        .then(async (res) => {
          if ('data' in res) {
            localStorage.setItem('token', res.data);
            localStorage.setItem('userId', String(res.id));

            dispatch({
              type: types.LOGIN_SUCCESS,
              payload: { token: res.data, resData: res },
            });

            const resTeam: any = await get(`/user/get-avatar-list/${res.id}`);
            dispatch({
              type: teamTypes.USERTEAMSLIST_SUCCESS,
              payload: { userTeamsList: resTeam.data },
            });

            history.push('/ownfranchise');
          } else {
            dispatch({
              type: types.LOGIN_FAIL,
              error: "Need to finish sign up",
            });
            history.push('/?signup=true', { continueSignUp: res });
          }
        })
        .catch((err) => console.log(err));
    } catch (error: any) {
      dispatch({
        type: types.LOGIN_FAIL,
        error: error.response?.data.error,
      });
    }
  };
};
