import { Row, Col } from "react-bootstrap";
import { Table } from "antd";
import "./LeagueScoreStyle.scss";
import { useMemo } from "react";
import { ViewPopover } from "../ViewPopover/ViewPopover";
import { RenderHelmet } from "../RenderHelmet/RenderHelmet";

export interface Props {
  data1?: any;
}

const LeagueScoreList: React.FC<Props> = ({ data1 }) => {
  const columns: any = useMemo(() => {
    const initialColumns = [
      {
        title: "FINAL",
        dataIndex: "final",
        fixed: "left",
        width: 250,
      },
      {
        title: "1",
        dataIndex: "1",
      },
      {
        title: "2",
        dataIndex: "2",
      },
      {
        title: "3",
        dataIndex: "3",
      },
      {
        title: "4",
        dataIndex: "4",
      },
      {
        title: "T",
        dataIndex: "t",
      },
    ];

    const otsNumber = data1.score[0].length - 4;

    if (otsNumber) {
      const OTsColumns = Array.from({ length: otsNumber }, (_, i) => ({
        title: `OT${i + 1}`,
        dataIndex: `ot${i + 1}`,
      }));

      initialColumns.splice(5, 0, ...OTsColumns);
    }

    return initialColumns;
  }, [data1]);

  const data: any = useMemo(
    () => [
      {
        key: "1",
        final: (
          <div className="leagueHeadMain">
            <div className="helmet">
              <RenderHelmet helmet={data1.helmets[0].helmet} logo={data1.helmets[0].logo} size={90}/>
            </div>
            <div>
              <ViewPopover utid={data1.tid1} teamName={data1.leaguename}>
                <h6 style={{ cursor: "pointer", width: "fit-content" }}>{data1.leaguename}</h6>
              </ViewPopover>
              <span>{data1.recordscore1}</span>
              <span>{data1.recordscore2}</span>
            </div>
          </div>
        ),
      },
      {
        key: "2",
        final: (
          <div className="leagueHeadMain">
            <div className="helmet">
              <RenderHelmet helmet={data1.helmets[1].helmet} logo={data1.helmets[1].logo} size={90}/>
            </div>
            <div>
              <ViewPopover utid={data1.tid2} teamName={data1.leaguename2}>
                <h6 style={{ cursor: "pointer", width: "fit-content" }}>{data1.leaguename2}</h6>
              </ViewPopover>
              <span>{data1.recordscore12}</span>
              <span>{data1.recordscore22}</span>
            </div>
          </div>
        ),
      },
    ],
    [data1]
  );

  for(let i = 0; i < 4; i++) {
    data[0][`${i + 1}`] = <span className="score">{data1.score[0][i]}</span>;
    data[1][`${i + 1}`] = <span className="score">{data1.score[1][i]}</span>;
  }

  if (data1.score[0].length > 4) {
    for (let i = 0; i < data1.score[0].length - 4; i++) {
      data[0][`ot${i + 1}`] = <span className="score">{data1.score[0][i + 4]}</span>;
      data[1][`ot${i + 1}`] = <span className="score">{data1.score[1][i + 4]}</span>;
    }
  }

  data[0].t = <span className="score scorered">{data1.score[0].reduce((acc: number, val: number) => acc + val, 0)}</span>;
  data[1].t = <span className="score scorered">{data1.score[1].reduce((acc: number, val: number) => acc + val, 0)}</span>;

  return (
    <div className="scoremain">
      <Row>
        <Col xl={6} lg={6} sm={12}>
          <div className="scorefinal">
            <Table
              columns={columns}
              pagination={false}
              dataSource={data}
              size="small"
            />
          </div>
        </Col>
        <Col xl={4} lg={4} sm={12}>
          <div className="leadersscore">
            <h5>LEADERS :</h5>
            <ul>
              <li>{data1.passtitle} :</li>
              <li>
                <span>
                  {data1.passsubtitle} &nbsp;{data1.passscoredigit}
                </span>
              </li>
            </ul>
            <ul>
              <li>{data1.rushtitle}</li>
              <li>
                <span>
                  {data1.rushsubtitle} &nbsp;{data1.rushscoredigit}
                </span>
              </li>
            </ul>
            <ul>
              <li>{data1.rectitle}</li>
              <li>
                <span>
                  {data1.recsubtitle} &nbsp;{data1.recscoredigit}
                </span>
              </li>
            </ul>
          </div>
        </Col>
        <Col xl={2} lg={2} sm={12}>
          <div className="scorebutton">
            <h5>{data1.leagueid}</h5>
            <div className="standscorebtn">
              {data1.button1}
              {data1.button2}
              {data1.button3}
              {data1.button4}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LeagueScoreList;
