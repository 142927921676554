/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Row, Col } from "react-bootstrap";
import { DownOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Table } from "antd";
import Title from "../Common/Title/Title";

const MintDropdown = () => {
  const menu = (
    <Menu className="specialdrop">
      <Menu.Item>FRANCHISE NAME</Menu.Item>
      <Menu.Item>FRANCHISE NAME</Menu.Item>
      <Menu.Item>FRANCHISE NAME</Menu.Item>
      <Menu.Item>FRANCHISE NAME</Menu.Item>
      <Menu.Item>FRANCHISE NAME</Menu.Item>
    </Menu>
  );
  const position = (
    <Menu className="specialdrop">
      <Menu.Item>Quarter Back</Menu.Item>
      <Menu.Item>Running Back</Menu.Item>
      <Menu.Item>Wide Receiver</Menu.Item>
      <Menu.Item>Tight End</Menu.Item>
      <Menu.Item>Offensive Line</Menu.Item>
      <Menu.Item>Select Position</Menu.Item>
    </Menu>
  );

  // SELECT PLAYER
  const offensiveLineColumns = [
    {
      title: "",
      dataIndex: "title",
    },
    {
      title: "PRUSH",
      dataIndex: "prush",
    },
    {
      title: "COV",
      dataIndex: "cov",
    },
    {
      title: "RSTOP",
      dataIndex: "rstop",
    },
    {
      title: "TACKL",
      dataIndex: "tackl",
    },
    {
      title: "OVERALL",
      dataIndex: "overhall",
    },
  ];
  const offensiveLinekData = [
    {
      key: "1",
      title: "MITCH TRAP",
      prush: "34",
      cov: "34",
      rstop: "34",
      tackl: "34",
      overhall: "77",
    },
    {
      key: "2",
      title: "MITCH TRAP",
      prush: "34",
      cov: "34",
      rstop: "34",
      tackl: "34",
      overhall: "77",
    },
    {
      key: "3",
      title: "MITCH TRAP",
      prush: "34",
      cov: "34",
      rstop: "34",
      tackl: "34",
      overhall: "77",
    },
    {
      key: "4",
      title: "MITCH TRAP",
      prush: "34",
      cov: "34",
      rstop: "34",
      tackl: "34",
      overhall: "77",
    },
    {
      key: "5",
      title: "MITCH TRAP",
      prush: "34",
      cov: "34",
      rstop: "34",
      tackl: "34",
      overhall: "77",
    },
    {
      key: "6",
      title: "MITCH TRAP",
      prush: "34",
      cov: "34",
      rstop: "34",
      tackl: "34",
      overhall: "77",
    },
  ];
  return (
    <>
      <div className="mt-3">
        <Row>
          <Col xl={6} lg={6} md={6} sm={6}>
            <div className="franchise-position">
              <Dropdown overlay={menu}>
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}>
                  SPECIAL FRANCHISE <DownOutlined />
                </a>
              </Dropdown>
            </div>
          </Col>
          <Col xl={6} lg={6} md={6} sm={6}>
            <div className="franchise-position">
              <Dropdown overlay={position}>
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}>
                  SPECIAL POSITITON <DownOutlined />
                </a>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </div>
      <div className="standingmain selectplayertable">
        <Title text="SELECT PLAYER" />
        <Table
          columns={offensiveLineColumns}
          pagination={false}
          dataSource={offensiveLinekData}
          size="small"
        />
      </div>
      <p className="click">CLICK ON ASSEST TO SELECT</p>
    </>
  );
};

export default MintDropdown;
