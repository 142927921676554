import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import ContactMain from "../../components/Contact/ContactMain";

const ContactScreen = () =>{
    return(
        <>
            <div className="pagelayout">
                <WithLoginNavbar />
                <ContactMain />
            </div>
        </>
    );
}

export default ContactScreen;