/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./FullPlayerStatStyle.scss";
import { Container, Col } from "react-bootstrap";
import Card from "../Common/Card/Card";
import CardBody from "../Common/Card/CardBody";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { PregameOverview, getTeamGameDepthChart } from "../../redux/actions/GameEngineAction";
import { RootState } from "../../redux/store";
import LoadingScreen from "./../Common/LoadingScreen/LoadingScreen";
import { useParams } from "react-router-dom";
import FullTeamStatHeader from "../FullTeamStat/FullTeamStatHeader";
import LeagueOverviewRating from "components/LeagueHeadToHead/TabDetail/OverviewTab/LeagueOverviewRating";
import { formationConfig, getFormationConfig, positionColumns } from "components/DefensiveDepthChart/depthChartConfig";
import DepthChartPosition from "components/FranchiseOverview/TabDetail/DepthChartTab/DepthChartPosition";
import { Position } from "types";
import { preparePlayersDepth } from "./helper";
import LeagueHeadTabMainMenu from "components/LeagueHeadToHead/LeagueHeadTabMainMenu";

const FullPlayerStatMain: React.FC = () => {
  const dispatch = useDispatch();
  const { franchiseId, gid }: any = useParams();

  const gameEngineStateLoader: any = useSelector(
    (state: RootState) => state.gameEngine.loader
  );

  const teamDepthChart = useSelector(
    (state: RootState) => state.gameEngine.teamDepthChart
  );

  const [preparedPlayers, setPreparedPlayers] = useState<any[]>([]);

  useEffect(() => {
    if (teamDepthChart) {
      setPreparedPlayers(preparePlayersDepth(teamDepthChart));
    } else {
      setPreparedPlayers([]);
    }
  }, [teamDepthChart]);

  useEffect(() => {
    dispatch(PregameOverview(gid));
    dispatch(getTeamGameDepthChart(franchiseId, gid, 'fullplayerstat'));
  }, [gid, franchiseId]);

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {!!gameEngineStateLoader && <LoadingScreen />}
      <Container>
        <FullTeamStatHeader />
        <LeagueHeadTabMainMenu />
        <Col xl={12} className="mb-3">
          <LeagueOverviewRating gid={gid} />
        </Col>
        <div className='pb-4'>
          <Card>
            <CardBody>
              {Object.keys(positionColumns)
                .filter(
                  (position) =>
                    position in formationConfig['ANY']
                )
                .map((position) => {
                  const key = position as Position;
                  console.log(key);
                  return (
                    <DepthChartPosition
                      key={key}
                      position={key}
                      title={positionColumns[key].title.toUpperCase()}
                      columns={positionColumns[key].columns}
                      slots={getFormationConfig("ANY")[key]}
                      players={preparedPlayers?.filter((p: any) => p?.ratings?.pos === key) || []}
                    />
                  );
                })}
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  )
}

export default FullPlayerStatMain
