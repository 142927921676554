import Error from "../../assets/images/common/error.svg";

const InsufficientTokenModal = () =>{
    return(
        <>
            <div className="startdraftmain" >
                <img src={Error} alt="erroricon" />
                <p>You have an insufficient GM Dynasty Token (GMDT) balance
                to complete the transaction. Use PayPal or Ethereum to
                complete the transaction.</p>
            </div> 
        </>
    );
}

export default InsufficientTokenModal;