import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { SeasonInfo } from 'screens/Season/types';
import { useWeekDetails } from './hooks/useWeekDetails';
import { RenderHelmet } from 'components/Common/RenderHelmet/RenderHelmet';
import BlueButton from 'components/Common/Button/BlueButton/BlueButton';
import history from 'routes/history';

type Props = {
  week: SeasonInfo['groupStage']['weeks'][0];
};

export const SeasonScheduleListItem: React.FC<Props> = ({ week }) => {
  const { loadWeekDetails, weekDetails, loading } = useWeekDetails(week?.id);

  let arrowCN = 'season-schedule-list-item-more';
  let dataCN = 'season-schedule-list-item-more-data';

  if (!!weekDetails) {
    arrowCN += ' season-schedule-list-item-more__expanded';
    dataCN += ' season-schedule-list-item-more-data__expanded';
  }

  return (
    <>
      <div className="season-schedule-list-item">
        <span className="season-schedule-list-item-name">
          <span>{week.title.split(' ')[0]}</span>
          <span>&nbsp;{week.title.split(' ')[1]}</span>
        </span>

        <span>START AT: {new Date(week.days[0].scheduledAt).toLocaleString()}</span>

        <span>GAME DAYS: {week.days.length}</span>

        {loading ? <LoadingOutlined /> : <DownOutlined className={arrowCN} onClick={loadWeekDetails} />}
      </div>
      <div className={dataCN}>
        {weekDetails?.length
          ? weekDetails.map((day, i) => (
              <div key={i} className="day">
                <span className="day-number">DAY {day.day_number}, </span>
                <span className="day-start-at">START AT: {new Date(day.scheduledAt).toLocaleString()}</span>
                <div className="day-games">
                  {day.H2HGames.map((g) => (
                    <div key={g.gid} className="day-games-item">
                      <div className="day-games-item-team">
                        <RenderHelmet 
                          helmet={g.won.helmet} 
                          logo={g.won.logo} 
                          mirror 
                          size={100} 
                          onClick={() => history.push(`/franchisedetail/${g.won.engId}`)} 
                        />
                        <span>{g.won.teamName}</span>
                      </div>
                      <div className="day-games-item-center">
                        <span className="day-games-item-center-score">
                          {'pts' in g.won && 'pts' in g.lost && `${g.won.pts} - ${g.lost.pts}`}
                        </span>
                        {'pts' in g.won && 'pts' in g.lost
                          ? <BlueButton text="RESULTS" onClick={() => history.push(`/game-results/${g.gid}`)} />
                          : g.gid && <BlueButton text="WATCH" onClick={() => history.push(`/gameplay?id=${g.gid}`)} />}
                      </div>
                      <div className="day-games-item-team day-games-item-team-right">
                        <RenderHelmet 
                          helmet={g.lost.helmet} 
                          logo={g.lost.logo} 
                          size={100} 
                          onClick={() => history.push(`/franchisedetail/${g.lost.engId}`)} 
                        />
                        <span>{g.lost.teamName}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
          : week.days.map((day, i) => (
              <div key={i}>
                <span>{day.title}</span>
                <span>&nbsp;START AT: {new Date(day.scheduledAt).toLocaleString()}</span>
              </div>
            ))}
      </div>
    </>
  );
};
