import { LeagueTeam, SeasonInfo } from 'screens/Season/types';

export const prepareTeams = (teams: any) => {
  return teams?.map((t:any, i:number) => ({
    name: t?.name || `Team ${i + 1}`,
    won: t?.W || 0,
    lost: t?.L || 0,
    pct: t?.PCT || 0,
    tied: t?.T || 0,
    home: t?.HOME || 0,
    road: t?.ROAD || 0,
    division: t?.DIV || 0,
    conference: t?.CONF || 0,
    pointsFor: t?.PA || 0,
    pointsAgainst: t?.PF || 0,
    pointDifferential: t?.DIFF || 0,
    streak: t?.STRK || 0,
  }));
};

type PreparedTeam = LeagueTeam & { key: string };

export const getConfTeams = (info: SeasonInfo): PreparedTeam[][] => {
  return info.conferences.map((conf) =>
    conf.divisions.reduce((acc: PreparedTeam[], div) => {
      const teams = div.teams.map((team) => ({
        ...team,
        key: team.id,
      }));
      acc.push(...teams);
      return acc;
    }, []),
  );
};
