/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./RosterTabStyle.scss";
import Card from "../../../Common/Card/Card";
import CardBody from "../../../Common/Card/CardBody";
import { Row, Col } from "react-bootstrap";
import { Table, Modal } from "antd";
import Title from "../../../Common/Title/Title";
import BlueButton from "../../../Common/Button/BlueButton/BlueButton";
import CircleCutIcon from "components/Common/Icon/CircleIcon/CircleCutIcon";
import CircleChevronIcon from "components/Common/Icon/CircleIcon/CircleChevronIcon";
import { useEffect, useState } from "react";
import ActiveRosterModal from "./ActiveRosterModal";
import RosterMoveModal from "./RosterMoveModal";
import ReleaseModal from "./ReleaseModal";
import ExportModal from "./ExportModal";
import DemoteModal from "./DemoteModal";
import PromoteModal from "./PromoteModal";
import Coming from "./Coming";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { RatingWithChange } from "./RatingWithChange";
import { alignAllColumnsToCenter } from "../DepthChartTab/helper";
import { wrapAllAntdTable } from "../DepthChartTab/ValueWrapper";

const RosterTabMain = () => {
   const { franchiseId } = useParams<{ franchiseId: string }>();
   const teamRosterData = useSelector((state: RootState) => state.gameEngine.teamRoster);
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   //SELECT CITY MODAL
   const [visible, setVisible] = useState(false);
   const [isModalVisible, setIsModalVisible] = useState(false);
   // const showModal = () => {
   //   setIsModalVisible(true);
   // };
   // const handleOk = () => {
   //   setIsModalVisible(false);
   // };
   const handleCancel = () => {
      setIsModalVisible(false);
   };

   // MODAL 1
   const [visible1, setVisible1] = useState(false);
   // const [isModalVisible1, setIsModalVisible1] = useState(false);
   // const showModal1 = () => {
   //   setIsModalVisible1(true);
   // };
   // const handleOk1 = () => {
   //   setIsModalVisible1(false);
   // };
   // const handleCancel1 = () => {
   //   setIsModalVisible1(false);
   // };

   // MODAL 2
   const [visible2, setVisible2] = useState(false);
   // const [isModalVisible2, setIsModalVisible2] = useState(false);
   // const showModal2 = () => {
   //   setIsModalVisible2(true);
   // };
   // const handleOk2 = () => {
   //   setIsModalVisible2(false);
   // };
   // const handleCancel2 = () => {
   //   setIsModalVisible2(false);
   // };

   // EXPORT MODAL
   const [visible3, setVisible3] = useState(false);
   // const [isModalVisible3, setIsModalVisible3] = useState(false);
   // const showModal3 = () => {
   //   setIsModalVisible3(true);
   // };
   // const handleOk3 = () => {
   //   setIsModalVisible3(false);
   // };
   // const handleCancel3 = () => {
   //   setIsModalVisible3(false);
   // };

   // DEMOTE MODAL
   const [visible4, setVisible4] = useState(false);
   // const [isModalVisible4, setIsModalVisible4] = useState(false);
   // const showModal4 = () => {
   //   setIsModalVisible4(true);
   // };
   // const handleOk4 = () => {
   //   setIsModalVisible4(false);
   // };
   // const handleCancel4 = () => {
   //   setIsModalVisible4(false);
   // };

   // PROMOTE MODAL
   const [visible5, setVisible5] = useState(false);
   // const [isModalVisible5, setIsModalVisible5] = useState(false);
   // const showModal5 = () => {
   //   setIsModalVisible5(true);
   // };
   // const handleOk5 = () => {
   //   setIsModalVisible5(false);
   // };
   // const handleCancel5 = () => {
   //   setIsModalVisible5(false);
   // };

   // PROMOTE MODAL
   const [visible6, setVisible6] = useState(false);
   // const [isModalVisible6, setIsModalVisible6] = useState(false);
   // const showModal6 = () => {
   //   setIsModalVisible6(true);
   // };
   // const handleOk6 = () => {
   //   setIsModalVisible6(false);
   // };
   // const handleCancel6 = () => {
   //   setIsModalVisible6(false);
   // };

   const activeRosterColumns: any = wrapAllAntdTable(
      alignAllColumnsToCenter([
         {
            title: "NAME",
            dataIndex: "activeRoster",
            fixed: "left",
         },
         {
            title: "NO",
            dataIndex: "no",
            sorter: (a: any, b: any) => +a.no - +b.no,
            render: (text: any) => {
              console.log('text no', text)
               return <> Number(text).toFixed(0)</>;
            },
         },
         {
            title: "POS",
            dataIndex: "pos",
            sorter: (a: any, b: any) => a.pos.localeCompare(b.pos),
         },
         {
            title: "HT",
            dataIndex: "height",
            sorter: (a: any, b: any) => a.height.localeCompare(b.height),
         },
         {
            title: "WT",
            dataIndex: "weight",
            sorter: (a: any, b: any) => +a.weight - +b.weight,
            render: (text: any) => Math.floor(text),
         },
         {
            title: "GAMES",
            dataIndex: "games",
            sorter: (a: any, b: any) => +a.games - +b.games,
            render: (text: any) => Number(text).toFixed(0),
         },
         {
            title: "RATING HIGH",
            dataIndex: "ratinghigh",
            sorter: (a: any, b: any) => +a.ratinghigh - +b.ratinghigh,
            render: (text: any) => Math.floor(text),
         },
         {
            title: "RATING",
            dataIndex: "rating",
            sorter: (a: any, b: any) => a.rating.props.rating - b.rating.props.rating,
            render: (text: any) => Math.floor(text),
         },
         {
            title: "STATUS",
            dataIndex: "status",
            sorter: (a: any, b: any) => a.status - b.status,
         },
         {
            title: "",
            dataIndex: "buttons",
         },
      ]), true
   );

   const rosterDataList = teamRosterData?.roster?.map((e: any, key: any) => {
      let info = {
         key: key + 1,
         activeRoster: (
            <>
               <Link to={`/playerdetail/${e?.pid}`} className="a_color">
                  {e.firstName || e.lastName ? `${e.firstName} ${e.lastName}` : "---"}
               </Link>
            </>
         ),
         no: e?.number ? format(e.number) : "---",
         pos: e?.pos ? e.pos : "---",
         height: e?.heightText ? e.heightText : "---",
         weight: e?.weight ? format(e.weight) : "---",
         games: e?.games ? format(e.games) : "---",
         ratinghigh: e?.highRating ? format(e.highRating) : "---",
         rating:
            e?.rating && (e?.ratingChange !== undefined || e?.ratingChange !== null) ? (
               <RatingWithChange rating={e.rating} ratingChange={e.ratingChange} />
            ) : (
               "---"
            ),
         status: e?.status ? e.status : "---",
         buttons: (
            <div className="overviewBtn RosterBtn">
               <CircleCutIcon onClick={() => setVisible6(true)}/>
               <CircleChevronIcon onClick={() => setVisible6(true)}/>
            </div>
         ),
      };
      console.log("info", info);
      return info;
   });

   const [activeRosterNewData, setActiveRosterNewData] = useState([]);
   const [taxiSquadNewData, setTaxiSquadNewData] = useState([]);

   useEffect(() => {
      // if (rosterDataList?.length > 0) {
      //   // sortArray(rosterDataList, {
      //   //   by: "pos",
      //   //   order: "asc",
      //   // });
      // }

      const filterRosterData = rosterDataList?.filter(
         (data: any) =>
            data.pos !== "GM" && data.pos !== "AG" && data.pos !== "HC" && data.pos !== "AC"
      );
      let activeRoster = filterRosterData?.slice(0, 53);
      setActiveRosterNewData(activeRoster);

      // let taxiSquad = rosterDataList?.slice(53, 64);
      // setTaxiSquadNewData(taxiSquad);
   }, [teamRosterData]);
   console.log("roster", activeRosterNewData);

   return (
      <>
         <Row>
            <Col xl={12} className="mb-5">
               <Card>
                  <CardBody>
                     <div className="standingmain">
                        <div className="table_head">
                           <Title text="ACTIVE Roster" />
                           <h6>{activeRosterNewData?.length} OF 53 ROSTER SPOTS FILLED</h6>
                        </div>
                        <Table
                           columns={activeRosterColumns}
                           pagination={false}
                           dataSource={activeRosterNewData}
                           // scroll={{ x: 1500 }}
                           size="small"
                        />
                     </div>
                     <div className="standingmain">
                        <div className="table_head">
                           <div>
                              <Title text="TAXI SQUAD" />
                              <h6 style={{ cursor: "pointer" }} className="titleBadge">
                                 {" "}
                                 IMPORT PLAYER
                              </h6>
                           </div>
                           <h6>{taxiSquadNewData?.length} OF 10 TAXI SPOTS FILLED</h6>
                        </div>
                        <Table
                           columns={activeRosterColumns}
                           pagination={false}
                           dataSource={taxiSquadNewData}
                           size="small"
                           // scroll={{ x: 1500 }}
                        />
                     </div>
                     {/* <BlueButton onClick={() => setVisible(true)} text="ROSTER MOVE" />
              <BlueButton onClick={() => setVisible1(true)} text="ACTIVE ROSTER" />
              <BlueButton onClick={() => setVisible2(true)} text="RELEASE" />
              <BlueButton onClick={() => setVisible3(true)} text="EXPORT" />
              <BlueButton onClick={() => setVisible4(true)} text="DEMOTE" />
              <BlueButton onClick={() => setVisible5(true)} text="PROMOTE" /> */}
                  </CardBody>
               </Card>
            </Col>
         </Row>

         {/* ACTIVE ROSTER MODAL */}
         <Modal
            centered
            className="rostererror"
            open={visible}
            onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            footer={[<BlueButton onClick={handleCancel} text="DONE" />]}
            width={450}
         >
            <ActiveRosterModal />
         </Modal>
         {/* ACTIVE ROSTER MODAL */}

         <Modal
            centered
            className="rostererror"
            open={visible1}
            onOk={() => setVisible1(false)}
            onCancel={() => setVisible1(false)}
            footer={[<BlueButton onClick={handleCancel} text="DONE" />]}
            width={450}
         >
            <RosterMoveModal />
         </Modal>

         {/* RELEASE MODAL */}
         <Modal
            centered
            className="rostererror"
            open={visible2}
            onOk={() => setVisible2(false)}
            onCancel={() => setVisible2(false)}
            footer={[<BlueButton onClick={handleCancel} text="RELEASE" />]}
            width={450}
         >
            <ReleaseModal />
         </Modal>

         {/* EXPORT MODAL */}
         <Modal
            centered
            className="rostererror"
            open={visible3}
            onOk={() => setVisible3(false)}
            onCancel={() => setVisible3(false)}
            footer={[<BlueButton onClick={handleCancel} text="RELEASE" />]}
            width={450}
         >
            <ExportModal />
         </Modal>

         {/* DEMOTE MODAL */}
         <Modal
            centered
            className="rostererror"
            open={visible4}
            onOk={() => setVisible4(false)}
            onCancel={() => setVisible4(false)}
            footer={[<BlueButton onClick={handleCancel} text="RELEASE" />]}
            width={450}
         >
            <DemoteModal />
         </Modal>

         {/* PROMOTE MODAL */}
         <Modal
            centered
            className="rostererror"
            open={visible5}
            onOk={() => setVisible5(false)}
            onCancel={() => setVisible5(false)}
            footer={[<BlueButton onClick={handleCancel} text="RELEASE" />]}
            width={450}
         >
            <PromoteModal />
         </Modal>

         {/* COMING MODAL */}
         <Modal
            centered
            className="rostererror comingmain"
            open={visible6}
            onOk={() => setVisible6(false)}
            onCancel={() => setVisible6(false)}
            footer={[<BlueButton onClick={handleCancel} text="RELEASE" />]}
            width={450}
         >
            <Coming />
         </Modal>
      </>
   );
};

export default RosterTabMain;

function format(unknown: number | string) {
   if (typeof unknown === "number") {
      console.log("unknown that is number", Math.floor(unknown));
      return Math.floor(unknown);
   }
   if (typeof unknown === "string") {
      console.log("unknown that is string", parseInt(unknown, 10));
      return parseInt(unknown);
   }
}
