import { Card, Col, Row } from "react-bootstrap";
import "../TabContentStyle.scss";
import { Input, notification } from "antd";
import { useCallback, useEffect, useState } from "react";
import paypal from "../../../../assets/images/wallet/payPal.svg";
import { PayPalButton } from "components/Common/PayPalButton/PayPalButton";
import ethreum from "../../../../assets/images/wallet/ethreum.svg";
import BlueButton from "../../../Common/Button/BlueButton/BlueButton";
import { useDispatch, useSelector } from "react-redux";
import { checkIsPromoUsed, cleanSavePaymentData } from "../../../../redux/actions/AppAction";
import { RootState } from "../../../../redux/store";
import { updateData } from "../../../../redux/actions/AuthAction";
import { useMetamaskPayment } from "helper/useMetamaskPayment";
import EtherPayLoader from "components/PurchaseFranchise/WalletConnected/EtherPayLoader";
import { useLocation } from "react-router-dom";
import history from "../../../../routes/history";
import { Balances } from "components/Common/Balances/Balances";
import { useFeeRates } from "helper/useFeeRates";

type PaymentTypes = "paypal" | "ethereum" | "card" | null;

type Props = {
   depositMessage?: string;
   closeModalMethod?: () => void;
};

const AddNewFunds: React.FC<Props> = ({
   depositMessage = "Choose or enter a custom amount that you would like to deposit",
   closeModalMethod,
}) => {
   const { PAYPAL_FEE_AMOUNT, PAYPAL_FEE_PERCENT } = useFeeRates();
   const dispatch = useDispatch();
   const [amount, setAmount] = useState(0);
   const [paymentMethod, setPaymentMethod] = useState<PaymentTypes>(null);
   const [inputError, setInputError] = useState<boolean>(false);
   const savePaymentError = useSelector((state: RootState) => state.app.savePaymentError);
   const savePaymentDataSuccess = useSelector(
      (state: RootState) => state.app.savePaymentDataSuccess
   );
   const userData = useSelector((state: RootState) => state.app.resData);
   const transactions = useSelector((state: RootState) => state.app.transactionsData);
   const hasTransactions = !!transactions?.length;
   const isPromoUsedByUser = useSelector((state: RootState) => state.app.isPromoUsed);

   const location = useLocation();

   const queryParams = new URLSearchParams(location.search);
   const redirectBack = queryParams.get("redirectBack");
   const handleRedirectBack = useCallback(() => {
      if (redirectBack) {
         history.push(`/${redirectBack}`);
      }
   }, [redirectBack]);

   const { metamaskLoader, proceedPayment, handelMetamaskConnect } = useMetamaskPayment(
      amount || 0
   );

   useEffect(() => {
      if (savePaymentError) {
         notification.error({
            description: savePaymentError,
            message: "PAYMENT VERIFICATION FAILED",
            placement: "bottomRight",
         });
      }
      if (savePaymentDataSuccess) {
         notification.success({
            description: "Payment successfull",
            message: "OK",
            placement: "bottomRight",
         });
         if (closeModalMethod) {
            closeModalMethod();
         }
         dispatch(cleanSavePaymentData());
         dispatch(updateData());
         if (userData && userData?.id) {
            dispatch(checkIsPromoUsed(userData?.id));
         }
         handleRedirectBack();
      }
   }, [
      savePaymentError,
      savePaymentDataSuccess,
      dispatch,
      userData,
      closeModalMethod,
      handleRedirectBack,
   ]);

   useEffect(() => {
      dispatch(cleanSavePaymentData());
   }, [dispatch]);

   const handleSetPaymentMethod = (paymentMethod: PaymentTypes) => {
      if (!amount || amount < 50 || amount > 999) {
         setInputError(true);
         return;
      } else {
         setInputError(false);
      }
      if (paymentMethod === "ethereum") {
         handelMetamaskConnect();
      }
      setPaymentMethod(paymentMethod);
   };

   const handleAmountChange = (input: number) => {
      if (input < 50 || input > 999) {
         handleSetPaymentMethod(null);
      }
      if (!input || input < 0) {
         setInputError(true);
      } else {
         setInputError(false);
      }
      setAmount(input);
   };

   if (PAYPAL_FEE_AMOUNT === undefined || PAYPAL_FEE_PERCENT === undefined) {
      return null;
   }

   const fee = amount * PAYPAL_FEE_PERCENT / 100 + PAYPAL_FEE_AMOUNT;

   return (
      <>
         {metamaskLoader && <EtherPayLoader />}
         <div>
            <h5 className="addnewfunds">ADD NEW FUNDS</h5>
            {/* <span>
              {hasTransactions && isPromoUsedByUser
                 ? depositMessage
                 : 'Deposit a minimum of $50 and we will pass you $50 extra bucks!'}
           </span> */}
            <Input
               className="addnewfundsinput mb-4"
               placeholder={"50"}
               value={amount}
               onChange={(e) => handleAmountChange(Number(e.target.value))}
               type="number"
               prefix="$"
               status={inputError ? "error" : ""}
            />
            <Row style={{ justifyContent: "space-around" }}>
               {[50, 100, 200].map((price) => (
                  <Card className="pricecard" onClick={() => handleAmountChange(price)} key={price}>
                     <Card.Text>{price} $</Card.Text>
                  </Card>
               ))}
            </Row>
            <div className="min-amount-error-container">
               {inputError && "Minumum amount is $ 50, maximum is $ 999"}
            </div>
            {(PAYPAL_FEE_AMOUNT !== undefined && PAYPAL_FEE_PERCENT !== undefined) && (
               <Balances 
                  data={[
                     { message: "DEPOSIT AMOUNT", value: "$ " + amount.toFixed(2) },
                     { message: "SECURITY & TRANSFER FEE", value: "$ " + fee.toFixed(2) },
                     { message: "TOTAL", value: "$ " + (amount + fee).toFixed(2) },
                  ]}
               />
            )}
            <div className="mb-4">GM Dynasty includes a security and transfer fee on all deposits. This fee helps cover the expense of maintaining secure wallets, transaction fees and hosting services.</div>
            <h5 className="blue">QUICK DEPOSIT OPTIONS</h5>
            <Row style={{ marginBottom: "10px", justifyContent: "space-around" }}>
               <Card className="paymentcard" onClick={() => handleSetPaymentMethod("paypal")}>
                  <Card.Text>
                     <img src={paypal} alt="paypal" />
                  </Card.Text>
               </Card>
               <Card className="paymentcard" onClick={() => handleSetPaymentMethod("ethereum")}>
                  <Card.Text>
                     <img src={ethreum} alt="ethereum" />
                  </Card.Text>
               </Card>
            </Row>
            {!inputError && (
               <div className="paymentbuttons">
                  {paymentMethod === "paypal" && amount && (
                     <PayPalButton
                        product={{
                           description: "ADD FUNDS",
                           price: amount,
                           orderType: "BALANCE",
                        }}
                     />
                  )}
                  {paymentMethod === "ethereum" && (
                     <BlueButton text="DEPOSIT VIA METAMASK" onClick={proceedPayment} />
                  )}
               </div>
            )}
         </div>
      </>
   );
};

export default AddNewFunds;
