import CardBody from 'components/Common/Card/CardBody';
import { SeasonTeams } from './SeasonTeams';
import Card from 'components/Common/Card/Card';
import { Row, Col } from 'react-bootstrap';
import { SeasonLeagueInfo } from './SeasonLeagueInfo';
import { SeasonLeaders } from './SeasonLeaders';
import { Results } from './Results';
import { SeasonPowerRankings } from './SeasonPowerRankings';

export const Overview: React.FC = () => (
  <Row>
    <Col xl={12} className="mb-4">
      <Card>
        <CardBody>
          <div>
            <SeasonLeagueInfo />
          </div>
        </CardBody>
      </Card>
    </Col>
    <Col xl={12} className="mb-4">
      <Card>
        <CardBody>
          <div>
            <SeasonTeams />
          </div>
        </CardBody>
      </Card>
    </Col>
    <Col xl={12} className="mb-4">
      <Card>
        <CardBody>
          <div>
            <SeasonLeaders />
          </div>
        </CardBody>
      </Card>
    </Col>
    <Col xl={8}>
      <Card>
        <CardBody>
          <Results />
        </CardBody>
      </Card>
    </Col>
    <Col xl={4} className="pb-3">
      <Card>
        <CardBody>
          <SeasonPowerRankings />
        </CardBody>
      </Card>
    </Col>
  </Row>
);
