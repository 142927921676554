import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { cleanInviteEmailError } from "../../redux/actions/AuthAction";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import "./MainContent.scss";
import "./Modal.scss";
import { socket } from "../../socket";
import { notification } from "antd";
import {
  getFullGameType,
  getPrizePool,
  getTeamsInGameString,
} from "helper/helper";
import { useFeeRates } from "helper/useFeeRates";
import { League } from "components/Common/LeagueHeader/LeagueHeader";
import { RenderTrophy } from "components/Common/RenderTrophy/RenderTrophy";
import { useGameData } from "helper/useGameData";
import { InviteToLeagueModal } from "./modals/InviteToLeagueModalt";
import { handlePublicGame } from "./helper/PublishGame";
import BlueButton from "components/Common/Button/BlueButton/BlueButton";
import { LeaveGameButton } from "./hooks/LeaveGameButton";

type Props = {
  gid: string | number;
  type: "H" | "S" | "E";
}

const MainContent: React.FC<Props> = ({ gid, type}) => {
  const dispatch = useDispatch();
  const { data, loadData } = useGameData(
    gid ? +gid : undefined,
    type as League["type"]
  );

  const { WALLET_TRANSACTION_FEE } = useFeeRates();

  const userData = useSelector((state: RootState) => state?.app?.resData);
  const inviteError = useSelector(
    (state: RootState) => state?.app?.sendInviteEmailError
  );
  const userTeams = useSelector(
    (state: RootState) => state.team.userTeamsList
  );

  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);

  useEffect(() => {
    dispatch(cleanInviteEmailError());
  }, []);

  useEffect(() => {
    if (inviteError) {
      notification.error({
        message: inviteError,
        placement: "bottomRight",
      });
      dispatch(cleanInviteEmailError());
    }
  }, [inviteError]);

  useEffect(() => {
    if (gid && userData?.id && data?.type) {
      socket.emit("start_polling", {
        gid,
        userId: userData?.id,
        type: data?.type,
      });

      return () => {
        socket.emit("stop_polling", {
          gid,
          userId: userData?.id,
          type: data?.type,
        });
      }
    }
  }, [gid, userData?.id, data?.type]);

  if (!gid || !type || !data) {
    return null;
  }

  const payoutPct =
    data?.payoutPct ||
    (WALLET_TRANSACTION_FEE !== undefined ? 100 - WALLET_TRANSACTION_FEE : 0);

  const numberOfTeams = data?.numberOfTeams || 2;
  const prizePool =
    data && data && data.fee
      ? getPrizePool(data.fee, payoutPct, numberOfTeams)
      : 0;

  const gameType = data?.type && getFullGameType(data?.type);

  const teamsString =
    gameType === "HEAD-TO-HEAD"
      ? "1 of 2 (50% full)"
      : data &&
        data.teams &&
        (data?.numberOfTeams || data?.totalTeams)
        ? getTeamsInGameString(data?.numberOfTeams || data?.totalTeams, data.teams.length, true)
        :"";

  const isInvitesAndPostAvailable = userTeams?.some(
    (team: { engId: number }) => team.engId === data?.teams?.[0]
  );

  const teamRatingString = `${data?.ratingRangeMin} to ${data?.ratingRangeMax}`;

  return (
    <>
      <div className="main-content">
        <div className="league-entered-title">
          <p>YOU ARE OFFICIALLY ENTERED INTO THE LEAGUE</p>
        </div>
        <div className="league-info-section">
          <div className="league-prize-pool">
            <span>$</span>
            <p>{prizePool}</p>
          </div>
          <div className="league-info">
            <div className="league-game-info">
              <p>
                {gameType} : {data?.name}
              </p>
            </div>
            <div className="league-additional-info">
              <p>
                TEAMS: <span className="league-info-value">{teamsString}</span>
              </p>
              <p>
                PRIZE POOL:{" "}
                <span className="league-info-value">$ {prizePool}</span>
              </p>
              <p>
                TEAM RATING:{" "}
                <span className="league-info-value">{teamRatingString}</span>
              </p>
            </div>
          </div>
          {data?.trophyId && (
            <RenderTrophy
              trophyId={data.trophyId}
              mode="full"
              style={{ width: "80px", height: "100%" }}
            />
          )}
        </div>
        <div className="instructions">
          <div className="instructions-text-section">
            <div className="instructions-text">
              <p>YOUR FRANCHISE HAS BEEN ASSIGNED A LEAGUE</p>
            </div>
            <div className="instructions-text">
              <p>
                UPON FILLING THE LEAGUE YOU WILL HAVE VISIBILITY TO YOUR
                COMPETITORS AND THE OFFICIAL SCHEDULE.
              </p>
            </div>
          </div>
          {isInvitesAndPostAvailable && (
            <>
              <BlueButton
                text="INVITE OTHER FRACHISES TO JOIN LEAGUE"
                className="button invite-button"
                onClick={() => setIsInviteModalVisible(true)}
              />
              {data.visibility === "private" && (
                <BlueButton
                  text="POST TO PUBLIC LEAGUES"
                  className="button invite-button"
                  onClick={async () => {
                    await handlePublicGame(+gid, type as League["type"]);
                    loadData();
                  }}
                />
              )}
            </>
          )}

          <LeaveGameButton game={data} />
        </div>
      </div>
      <InviteToLeagueModal
        open={isInviteModalVisible}
        onClose={() => setIsInviteModalVisible(false)}
        league={data}
      />
    </>
  );
};

export default MainContent;
