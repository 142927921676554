import { League } from 'components/Common/LeagueHeader/LeagueHeader';
import { formationConfig } from '../components/DefensiveDepthChart/depthChartConfig';
import { TrophyBeforePurchase } from 'components/PurchaseTrophy/types';
import { AvatarDataUnit } from 'components/PurchaseFranchise/Common/Preview';

export interface ActionsCallbacks {
  onSuccess?: (message?: string) => void;
  onError?: (message?: string) => void;
}

export type Position = 'QB' | 'RB' | 'WR' | 'TE' | 'OL' | 'DL' | 'LB' | 'CB' | 'S' | 'K' | 'P' | 'KR';
export type Formations = keyof typeof formationConfig;

// export type FormationConfig = {
//   [K in Formations]: {
//     [P in Position]: string[]
//   }
// }

export type Player = {
  id: number;
  name: string;
  pos: string;
  stat: any;
  skills: any[];
  injury: any;
  injured: boolean;
  jerseyNumber: string;
  ovrs: {
    [K in Position]: number;
  };
};

export type OffensiveStrategy = {
  deviation: number;
  '1stDownRun': number;
  shortYdsRun: number;
  longYdsRun: number;
  goOnFourth: number;
  insideRuns: number;
  pctPassGun: number;
};
// Partial<Record<MyEnum, number>>;

export type TeamGamePlan = {
  schemes: {
    defense: {
      selectedSid?: number;
      sets: Array<
        {
          [K in Position]: number;
        } & {
          name: string;
          sid: number;
          strategy: any;
        }
      >;
    };
    offense: {
      selectedSid?: number;
      sets: Array<
        {
          [K in Position]: number;
        } & {
          name: string;
          sid: number;
          strategy: OffensiveStrategy;
        }
      >;
    };
    special: any;
  };
};

export type TeamDepthChartList = Array<TeamDepthChart>;
//   [K in Formations]?: TeamDepthChart
// }

export type TeamDepthChart = {
  [K in Position]?: Array<TeamDepthChartEntry>;
};

export type DepthChartRatings = {
  elu?: number;
  ovr: number;
  pcv?: number;
  prs?: number;
  rns?: number;
  skills?: string[];
  spd?: number;
  stre?: number;
  tck?: number;
  hnd?: number;
  rbk?: number;
  rec?: number;
  run?: number;
  pass?: number;
  punt?: number;
  kick?: number;
  pbk?: number;
  hgt?: number;
  endu?: number;
  thv?: number;
  tha?: number;
  thp?: number;
  rtr?: number;
  kpw?: number;
  kac?: number;
  ppw?: number;
  pac?: number;
};

export type TeamDepthChartEntry = {
  firstName: string;
  heightText: string;
  lastName: string;
  weight: number;
  pid: number;
  pos: Position;
  ratings: DepthChartRatings;
};

type H2HActivity = {
  gid: number;
  teams: number[];
  status: '' | 'pregame' | 'inProgress' | 'complete';
  pregameEnd: Date | string;
  score?: Record<number, number>; // only for inProgress or complete
};

type EliminationActivity = {
  gid: number;
  numberOfTeams: number;
  teamsJoined: number;
  round?: string; // only for inProgress
  startAt: Date | string;
  status: 'waitingForPlayers' | 'waitingForStart' | 'inProgress' | 'complete';
};

type SeasonActivity = {
  gid: string;
  startAt: Date | string;
  finishAt: Date | string | null;
  status: 'pending' | 'running' | 'completed';
  numberOfTeams: number;
  teamsJoined: number;
};

type Activity = {
  H2H?: H2HActivity;
  elimination?: EliminationActivity;
  season?: SeasonActivity;
};

export type UserTeamsList = Array<{
  approval: number;
  baseRate: string;
  bg: number;
  city: number;
  created: Date;
  engId: number;
  helmet: number;
  id: number;
  img: string;
  jsonFile: any;
  knobs: number;
  logo: number;
  playerReveal: number;
  rejectMessage: string | null;
  teamName: string;
  teamRating: string; // numeric
  transactionId: number;
  updateStatus: number;
  status: number;
  updated: Date;
  userId: number;
  userName: string;
  activity?: Activity;
  ovr?: number;
  winRate?: number;
}>;

type PlayerStat = {
  gp: number;
  min: number;
  drives: number;
  totStartYds: number;
  timePos: number;
  pts: number;
  fmb: number;
  fmbLost: number;
  pssCmp: number;
  pss: number;
  pssYds: number;
  pssTD: number;
  pssInt: number;
  pssLng: number;
  pssSk: number;
  pssSkYds: number;
  rus: number;
  rusYds: number;
  rusTD: number;
  rusLng: number;
  tgt: number;
  rec: number;
  recYds: number;
  recTD: number;
  recLng: number;
  pr: number;
  prYds: number;
  prTD: number;
  prLng: number;
  kr: number;
  krYds: number;
  krTD: number;
  krLng: number;
  defInt: number;
  defIntYds: number;
  defIntTD: number;
  defIntLng: number;
  defPssDef: number;
  defFmbFrc: number;
  defFmbRec: number;
  defFmbYds: number;
  defFmbTD: number;
  defFmbLng: number;
  defSk: number;
  defTckSolo: number;
  defTckAst: number;
  defTckLoss: number;
  defSft: number;
  fg0: number;
  fga0: number;
  fg20: number;
  fga20: number;
  fg30: number;
  fga30: number;
  fg40: number;
  fga40: number;
  fg50: number;
  fga50: number;
  fgLng: number;
  xp: number;
  xpa: number;
  pnt: number;
  pntYds: number;
  pntLng: number;
  pntTB: number;
  pntIn20: number;
  pntBlk: number;
  pen: number;
  penYds: number;
};

export type TeamStat = PlayerStat & {
  oppDrives: number;
  oppTotStartYds: number;
  oppTimePos: number;
  oppPts: number;
  oppFmb: number;
  oppFmbLost: number;
  oppPssCmp: number;
  oppPss: number;
  oppPssYds: number;
  oppPssTD: number;
  oppPssInt: number;
  oppPssLng: number;
  oppPssSk: number;
  oppPssSkYds: number;
  oppRus: number;
  oppRusYds: number;
  oppRusTD: number;
  oppRusLng: number;
  oppTgt: number;
  oppRec: number;
  oppRecYds: number;
  oppRecTD: number;
  oppRecLng: number;
  oppPr: number;
  oppPrYds: number;
  oppPrTD: number;
  oppPrLng: number;
  oppKr: number;
  oppKrYds: number;
  oppKrTD: number;
  oppKrLng: number;
  oppDefInt: number;
  oppDefIntYds: number;
  oppDefIntTD: number;
  oppDefIntLng: number;
  oppDefPssDef: number;
  oppDefFmbFrc: number;
  oppDefFmbRec: number;
  oppDefFmbYds: number;
  oppDefFmbTD: number;
  oppDefFmbLng: number;
  oppDefSk: number;
  oppDefTckSolo: number;
  oppDefTckAst: number;
  oppDefTckLoss: number;
  oppDefSft: number;
  oppFg0: number;
  oppFga0: number;
  oppFg20: number;
  oppFga20: number;
  oppFg30: number;
  oppFga30: number;
  oppFg40: number;
  oppFga40: number;
  oppFg50: number;
  oppFga50: number;
  oppFgLng: number;
  oppXp: number;
  oppXpa: number;
  oppPnt: number;
  oppPntYds: number;
  oppPntLng: number;
  oppPntTB: number;
  oppPntIn: number;
  oppPntBlk: number;
  oppPen: number;
  oppPenYds: number;
  ptsQtrs: number[];
};

type h2hGameReady = {
  utid: number;
  ready: boolean;
};

type h2hGameHistory = {
  timestamp: Date;
  action: string;
  desc: string;
};
type h2hGamePaymentBook = {
  utid: number;
  validTo: Date;
};

export type H2HGame = {
  gid: number;
  teams: number[];
  name: string;
  fee: number;
  ratingRangeMin: number;
  ratingRangeMax: number;
  visibility: 'public' | 'private';
  created: Date;
  payoutPct: number;
  type: 'H';
  ready: h2hGameReady[];
  duration?: 'ongoing' | 'oneTime';
  pregameEnd: Date | '';
  joinCode: string;
  status: '' | 'pregame' | 'inProgress' | 'complete';
  history?: h2hGameHistory[];
  paymentBook?: h2hGamePaymentBook[];
};

export type EliminationGame = {
  gid: number;
  teams: number[];
  name: string;
  fee: number;
  ratingRangeMin: number;
  ratingRangeMax: number;
  visibility: 'public' | 'private';
  created: Date;
  payouts: number[];
  type: 'E';
  joinCode: string;
  status: string; //todo: replace with existing statuses when they will be ready
  history?: h2hGameHistory[];
  paymentBook?: h2hGamePaymentBook[];
  numberOfTeams: number;
  tree: any[]; //todo: replace with exact type
  gameMode: string;
  startAt: Date;
};

export type City = {
  id: number;
  name: string;
  price: number | string;
  priceIfTeamIsFirst: number | string;
  baseRatingFrom: number;
  BaseRatingTo: number;
  image: string;
  cityCount: number;
};

export type FeeRates = {
  PAYPAL_FEE_AMOUNT: number;
  PAYPAL_FEE_PERCENT: number;
  WALLET_TRANSACTION_FEE: number;
};

export type CreateOrderRequestBodyData = {
  userId: number;
  userName: string;
  amount: string;
  fee: string;
  promoCredits: number;
  status: string;
  oderId: string;
  transactionId: string;
  emailAddress: string;
  ether: string;
  partnerCode: string;
  paymentType: string;
  metamaskAccount: string | null;
  city: number;
  orderType: string;
  walletBalance: string | number;
  gid?: number;
  type?: League['type'];
  trophyData?: TrophyBeforePurchase;
};

export type LeaveGameData = {
  success?: boolean;
  error: string;
  gid: number;
};

export type EliminationTreeValue = {
  gid: string | number;
  teams: [number | ' ', number | ' '];
  winner?: number;
  winTimestamp?: string | Date;
  scores?: { [key: string]: number };
};

export type EliminationOverview = {
  teams: number[];
  name: string;
  fee: number;
  ratingRangeMin: number;
  ratingRangeMax: number;
  payouts: number[];
  type: 'E';
  tree: EliminationTreeValue[][];
  numberOfTeams: number;
  startAt: Date;
  pregameTime: number;
  gameMode: string;
  paceOfPlay: string | number;
  status: string;
  gid: number;
  teamNames: { [key: string]: string };
  thirdPlaceGame?: EliminationTreeValue;
  helmets?: HelmetDataType[];
  trophyId?: number;
  groupId?: number;
};

export type EliminationDetails = EliminationTreeValue & {
  round: string;
  lost: {
    utid: number;
    pts: number;
  };
  won: {
    utid: number;
    pts: number;
  };
  records: {
    '0': {
      utid: number;
      won: number;
      lost: number;
      tied: number;
    };
    '1': {
      utid: number;
      won: number;
      lost: number;
      tied: number;
    };
  };
  opponentName?: string;
  gid: number;
};

export type HelmetDataType = {
  engId: number;
  helmet: number;
  logo: number;
  userName: string;
  teamName: string;
};

export type NotificationSettings = {
  transactionReciept: boolean;
  leagueStarted: boolean;
  eliminationRoundStarted: boolean;
  leagueEnd: boolean;
  leagueInvites: boolean;
};

export type LeaderPlayer = {
  pid: string;
  firstName: string;
  lastName: string;
};
export type LeaderPassing = LeaderPlayer & Pick<TeamStat, 'pss' | 'pssCmp' | 'pssYds' | 'pssTD' | 'pssInt'>;
export type LeaderRushing = LeaderPlayer & Pick<TeamStat, 'rus' | 'rusYds' | 'rusTD'>;
export type LeaderReceiving = LeaderPlayer & Pick<TeamStat, 'rec' | 'recYds' | 'recTD'>;

export interface InGameLeaderBoard {
  passing: Array<LeaderPassing | null>;
  rushing: Array<LeaderRushing | null>;
  receiving: Array<LeaderReceiving | null>;
}

export interface LeaderRowProps {
  title: string;
  teams: Array<{ player: string; statsRows: string } | null>;
}
export interface LeaderStatsProps<T> {
  data: Array<T | null>;
  type: 'passing' | 'receiving' | 'rushing';
}

export interface InGameTeamsStats {
  totalPlays: Array<number>;
  totalYards: Array<number>;
  passing: Array<number>;
  compAtt: Array<string>;
  ydsPerPass: Array<string>;
  intPass: Array<number>;
  skYdsLost: Array<string>;
  yardsPerPlay: Array<string>;
  rushing: Array<number>;
  rusAtmp: Array<number>;
  ydsPerRush: Array<string>;
  penalties: Array<string>;
  turnovers: Array<number>;
  fumbLost: Array<number>;
  spcTeamsTds: Array<number>;
  // posession: Array<string>;
}

export interface InGameStatRow {
  label: string;
  isMain: boolean;
}

export type InGameStatsRow = Record<keyof InGameTeamsStats, InGameStatRow>;

export interface InGameStatsRows {
  totalPlays: { label: string; isMain: true };
}
export interface GameSummaryTeam {
  abbrev: string;
  name: string;
  ovr: number;
  stat: { pts: number; ptsQtrs: Array<number> };
  utid: number;
}

export enum SpecialFeatureCode {
  ACCURATE_PASSER = 'Pa',
  DEEP_PASSER = 'Pd',
  SMART_PASSER = 'Ps',
  ATHLETIC = 'A',
  EXPLOSIVE = 'X',
  HANDS = 'H',
  PASS_BLOCKER = 'Bp',
  RUN_BLOCKER = 'Br',
  PASS_RUSHER = 'PR',
  RUN_STOPPER = 'RS',
  PASS_LOCKDOWN = 'L',
}

export enum SpecialFeatureDisplayName {
  ACCURATE_PASSER = 'ACCURATE PASSER',
  DEEP_PASSER = 'DEEP PASSER',
  SMART_PASSER = 'SMART PASSER',
  ATHLETIC = 'ATHLETIC',
  EXPLOSIVE = 'EXPLOSIVE',
  HANDS = 'HANDS',
  PASS_BLOCKER = 'PASS BLOCKER',
  RUN_BLOCKER = 'RUN BLOCKER',
  PASS_RUSHER = 'PASS RUSHER',
  RUN_STOPPER = 'RUN STOPPER',
  PASS_LOCKDOWN = 'PASS LOCKDOWN',
}

export enum TeamStatus {
  DEFAULT = 1,
}

export type FranchisePerformance = {
  elimWon: number;
  elimLost: number;
  finalsReached: number;
};

export type HelmetData = {
  engId: number;
  helmet: string;
  logo: string;
  userName: string;
  teamName: string;
  userId: number;
};

type H2HOverviewPlayer = {
  id: number;
  injured: boolean;
  injury: {
    gamesRemaining: number;
    type: string;
    playingThrough: boolean;
  };
  jeresyNumber: string; // numeric
  name: string;
  ovrs: {
    [K in Position]: number;
  };
  pos: Position;
  skills: string[];
  stat: PlayerStat;
};

type H2HOverviewTeam = {
  ovr: number;
  teamName: string;
  utid: number;
  player: H2HOverviewPlayer[];
  stat: TeamStat;
};

export type H2HOverview = {
  gid: number;
  teams: {
    0: H2HOverviewTeam;
    1: H2HOverviewTeam;
  };
};

export * from './profile';

export type PlayerLeader = {
  firstName: string;
  lastName: string;
  position: Position;
  avgValue: number;
  pid: number;
  pssYds?: number;
  rusYds?: number;
  recYds?: number;
  defTckSolo?: number;
  defSk?: number;
  defInt?: number;
  gp: number;
};

export type SeasonPlayerLeader = {
  pos: Position;
  name: string;
  utid: number;
  pssYds: number;
  pid: number;
};

type RecordData = {
  utid: number;
  won: number;
  lost: number;
  tied: number;
};

type WonLost = {
  pts: number;
  utid: number;
};

export type LeagueResult = {
  gid: number;
  round: string;
  lost: WonLost;
  won: WonLost;
  records: {
    '0': RecordData;
    '1': RecordData;
  };
  teamNames: Record<string, string>;
};

export type Avatar = {
  bg: AvatarDataUnit[];
  helmet: AvatarDataUnit[];
  knobs: AvatarDataUnit[];
  logo: AvatarDataUnit[];
  city: AvatarDataUnit[];
};

type H2HStatus = '' | 'pregame' | 'inProgress' | 'complete';
type eliminationStatus = 'waitingForPlayers' | 'waitingForStart' | 'inProgress' | 'complete';
type seasonStatus = 'pending' | 'running' | 'completed';

export type ActiveGame = {
  gid: number | string;
  type: 'S' | 'H' | 'E';
  status: H2HStatus | eliminationStatus | seasonStatus;
  teams: number[];
  startAt?: Date | string; // for season or elimination
};

export type OwnerStats = {
  fundsInPlay: number;
  noOfActiveGames: number;
  noOfTeams: number;
  totalWins: number;
  winPercentage: number;
};
