import Card from "components/Common/Card/Card";
import CardBody from "components/Common/Card/CardBody";
import PlayerCareerStats, { CareerStats } from "../PlayerCareerStats";
import PlayerCareerRecentGame, { RecentGame } from "../PlayerCareerRecentGame";
import PlayerAward, { Award } from "../PlayerAward";
import { StatisticalAchievements } from "../StatisticalAchievements";
import { StatisticalAchievementsType } from "../types";

type Props = {
  careerStats: CareerStats;
  recentGames: RecentGame[];
  awards: Award[];
  statisticalAchievements: StatisticalAchievementsType;
};

export const CareerTab: React.FC<Props> = ({
  careerStats,
  recentGames,
  awards,
  statisticalAchievements,
}) => (
  <Card>
    <CardBody>
      <div className="playerDetailTab">
        <div className="tab__section">
          <h5 className="tab__section_heading">career stats</h5>
          <PlayerCareerStats careerStats={careerStats ?? {}} />
        </div>
        <div className="tab__section">
          <h5 className="tab__section_heading">recent games</h5>
          <PlayerCareerRecentGame recentGames={recentGames ?? []} />
        </div>
        <div className="tab__section">
          <h5 className="tab__section_heading">awards</h5>
          <PlayerAward playerAwards={awards || []} />
        </div>
        <div className="tab__section">
          <h5 className="tab__section_heading">statistical achievements</h5>
          <StatisticalAchievements
            statisticalAchievements={statisticalAchievements}
          />
        </div>
      </div>
    </CardBody>
  </Card>
);
