import { Popover } from "antd";
import { League } from "components/Common/LeagueHeader/LeagueHeader";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { isLeaveAllowed } from "../helper/IsLeaveAllowed";
import { useCountdown } from "helper/useCoundown";
import PinkButton from "components/Common/Button/PinkButton/PinkButton";
import { useLeaveGame } from "./useLeaveGame";
import { LeaveGameModal } from "../LeaveGameModal";

type Props = {
  game: League;
  isGameList?: boolean;
};

export const LeaveGameButton: React.FC<Props> = ({
  game,
  isGameList,
}) => {
  const userTeams = useSelector(
    (state: RootState) => state.team.userTeamsList
  );
  const [allowTime, setAllowTime] = useState<Date | null>(null);
  const [isAllowedToLeave, setIsAllowedToLeave] = useState(false);
  const { handleLeaveGame } = useLeaveGame(game?.gid);
  const [min, sec] = useCountdown(allowTime || "");
  const [teamToLeave, setTeamToLeave] = useState<number | null>(null);

  
  const handleLeaveButtonClick = (engId: number) => {
    if (game && game?.gid && game.fee !== undefined) {
      if (game.fee === 0) {
        handleLeaveGame(+game?.gid, game.type, engId);
      } else {
        setTeamToLeave(engId);
      }
    }
  };

  useEffect(() => {
    const userTeamIds = userTeams?.map((team) => team.engId);
    if (userTeamIds && game?.history) {
      const { isPossible, timeout } = isLeaveAllowed(game?.history, userTeamIds);
      setIsAllowedToLeave(isPossible);
      if (timeout && !isPossible) {
        setAllowTime(new Date(Date.now() + timeout));
        const timer = setTimeout(() => {
          setIsAllowedToLeave(true);
        }, timeout);

        return () => clearTimeout(timer);
      } else {
        setAllowTime(null);
      }
    }
  }, [game, userTeams]);

  const LeaveButton: React.FC<{ onClick?: any }> = ({ onClick }) => (
    <PinkButton
      onClick={() => {
        typeof onClick === "function" && onClick();
      }}
      disabled={!isAllowedToLeave}
      text={isAllowedToLeave ? "LEAVE" : `${+min || 0}:${+sec || 0}`}
      className={!isGameList ? "button waiting-button join-game-button" : "join-game-button"}
    />
  );

  return (
    <>
      {game?.type === "E" ? (
        <Popover
          trigger={["click"]}
          content={userTeams
            ?.filter((team) => game?.teams?.includes(team.engId))
            ?.map((team, index, arr) => (
              <>
                <div
                  className="link-like"
                  key={team.engId}
                  onClick={() => handleLeaveButtonClick(team.engId)}
                >
                  {team.teamName}
                </div>
                {index !== arr.length - 1 && <hr />}
              </>
            ))}
        >
          <LeaveButton />
        </Popover>
      ) : (
        <LeaveButton onClick={() => handleLeaveButtonClick(game?.teams?.[0] as number)} />
      )}
      <LeaveGameModal
        open={!!teamToLeave}
        onCancel={() => setTeamToLeave(null)}
        onOk={() =>{
          console.log('CLICK')
          game?.gid && handleLeaveGame(game?.gid, game?.type, teamToLeave as number)
        }
        }
      />
    </>
  );
};
