import { LeagueShortInfo } from 'components/LeagueShortInfo/LeagueShortInfo';
import { useSeasonInfo } from 'screens/Season/hooks/useSeasonInfo';

export const SeasonLeagueInfo: React.FC = () => {
  const { seasonInfo } = useSeasonInfo();

  if (!seasonInfo) {
    return null;
  }

  return (
    <LeagueShortInfo
      type="SEASON"
      name={seasonInfo.title}
      numberOfTeams={seasonInfo.conferences.length === 1 ? 16 : 32}
      prize={0} // TODO: fix season prize pool
      // ratingRangeMin={seasonInfo.ratingRangeMin}
      // ratingRangeMax={seasonInfo.ratingRangeMax}
      startAt={seasonInfo.startAt}
      // @ts-ignore
      trophyId={seasonInfo?.trophyId}
      // @ts-ignore
      groupId={seasonInfo?.groupId}
    />
  );
};
