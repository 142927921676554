import { TrophyAssetsObject } from "redux/actions/TrophyAssets/types";
import { PATH_TO_TROPHY_FOLDER } from "../TrophyPreview/TrophyPreview";
import "./SelectorPopover.scss";
import Title from "components/Common/Title/Title";
import { getAssetLevel, getColorFromBgName } from "components/PurchaseTrophy/helper";

const possibleLevels = [0, 1, 2];

type Props = {
  title: string;
  imageArray: TrophyAssetsObject["cup"];
  onSelect: (trophy: TrophyAssetsObject["cup"][0]) => void;
  mode: "image" | "bg" | "corner";
  imageBG?: string;
};

const getSortedData = (data: TrophyAssetsObject["cup"]): Record<string, TrophyAssetsObject["bg"]> => {
  const dataWithLevels = data.map((item) => {
    const level = getAssetLevel(item.max_prestige_level);
    return {
      ...item,
      level: level,
    };
  });

  const levelsObject = possibleLevels.reduce((acc, level) => {
    return {
      ...acc,
      [level]: dataWithLevels.filter((item) => item.level === level),
    };
  }, {});

  return levelsObject;
};

export const SelectorPopover: React.FC<Props> = ({
  title,
  imageArray,
  onSelect,
  mode,
  imageBG,
}) => {
  const content = (() => {
    const dataWithLevels = getSortedData(imageArray);
    if (imageArray[0].filename.includes("corner") && mode === "corner") {
      console.log(dataWithLevels)
    }

    switch (mode) {
      case "image":
        return (
          <>
            {Object.keys(dataWithLevels).map((level) => (
              <>
                <h6 key={level}>LEVEL {level}</h6>
                {dataWithLevels[level].map((assert) => (
                  <img
                    key={assert.id}
                    onClick={() => onSelect(assert)}
                    className="property-selector-image"
                    src={`${PATH_TO_TROPHY_FOLDER}${assert.filename}`}
                    alt={assert.name || assert.filename}
                    style={{ backgroundColor: imageBG }}
                  />
                ))}
              </>
            ))}
          </>
        );
      case "bg":
        return (
          <>
            {imageArray.map((assert) => {
              const color = getColorFromBgName(assert.filename);
              return (
                <div
                  onClick={() => onSelect(assert)}
                  style={{ backgroundColor: `#${color}` }}
                  className="property-selector-color"
                />
              );
            })}
          </>
        );
      case "corner":
        return (
          <>
            {Object.keys(dataWithLevels).map((level) => (
              <>
                <h6 key={level}>LEVEL {level}</h6>
                {dataWithLevels[level].map((assert) => (
                  <div className="corner-container" key={assert.id}>
                    <img
                      key={assert.id}
                      onClick={() => onSelect(assert)}
                      className="property-selector-corner-image"
                      src={`${PATH_TO_TROPHY_FOLDER}${assert.filename}`}
                      alt={assert.name || assert.filename}
                    />
                  </div>
                ))}
              </>
            ))}
            
          </>
        );
      default:
        return null;
    }
  })();

  return (
    <div className="property-selector-image-container">
      <Title text={title} />
      {content}
    </div>
  );
};
