/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import metamaskconnect from "../../assets/images/wallet/metaMaskConnected.png";
import { Link } from "react-router-dom";
import { Divider, Table } from "antd";
import BlueButton from "../Common/Button/BlueButton/BlueButton";
import Input from "../Common/Form/Input/Input";
import ethreum from "../../assets/images/wallet/ethreum.svg";
import paypal from "../../assets/images/wallet/payPal.svg";
import redtick from "../../assets/images/wallet/redTick.svg";
import tokens from "../../assets/images/leaguePayment/tokenlogo.png";
import { Modal } from "antd";
import InsufficientTokenModal from "./InsufficientTokenModal";
import InsufficientEthereumModal from "./InsufficientEthereumModal";

const MintPlayerPayment = () => {
  // COUPON CODE FIELD
  const [username, setUsername] = useState("");

  // PAYMENT AMOUNT TABLE
  const offensiveLineColumns = [
    {
      title: "PURCHASE",
      dataIndex: "purchase",
    },
    {
      title: "AMT",
      dataIndex: "amount",
    },
    {
      title: "ETH",
      dataIndex: "eth",
    },
  ];
  const offensiveLinekData = [
    {
      key: "1",
      purchase: "FRANCHISE",
      amount: "$ 35",
      eth: "0.031",
    },
    {
      key: "2",
      purchase: "TOTAL",
      amount: "$ 35",
      eth: "0.031",
    },
  ];

  //TOKEN BALANCE INSUFFICIENT
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // MODAL 2
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const showModal1 = () => {
    setIsModalVisible1(true);
  };
  const handleOk1 = () => {
    setIsModalVisible1(false);
  };
  const handleCancel1 = () => {
    setIsModalVisible1(false);
  };
  return (
    <>
      <div className="walletconnectedmain">
        <h5>WALLET CONNECTED</h5>
        <img src={metamaskconnect} alt="metamask" className="mb-3" />
        <ul>
          <li className="wallethead">WALLET ID :</li>
          <li>0xC74E…30a7</li>
        </ul>
        <ul>
          <li className="wallethead">ETH BALANCE :</li>
          <li>0.300</li>
        </ul>
      </div>
      <div className="franchisecity mt-4 mb-3">
        <h5>FRANCHISE CITY</h5>
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="letter5 fontweight500 mb-0">RANDOM SELECTION</h6>
          <Link to="" className="blue fontweight700 letter5 mb-0">
            CHANGE
          </Link>
        </div>
      </div>
      <Divider className="pricelinehori" />
      <Table
        columns={offensiveLineColumns}
        pagination={false}
        dataSource={offensiveLinekData}
        size="small"
        className="mt-3 paymentprice"
      />
      <div className="paymentoption mt-3">
        <h5>SELECT PAYMENT OPTION</h5>
        <Row>
          <Col lg={6}>
            <li>
              <input type="radio" name="test" id="cb1" />
              <label htmlFor="cb1">
                <img src={paypal} alt="paypal" />
              </label>
            </li>
          </Col>
          <Col lg={6}>
            <li>
              <input type="radio" name="test" id="cb2" />
              <label htmlFor="cb2">
                <img src={ethreum} alt="paypal" />
              </label>
            </li>
          </Col>
          <Col lg={12}>
            <li className="partnercode">
              <input type="radio" name="test" id="cb3" />
              <label htmlFor="cb3">PARTNER CODE</label>
            </li>
          </Col>
        </Row>
        <Input
          type="text"
          value={username}
          onChange={setUsername}
          placeholder="ENTER PARTNER CODE"
        />
      </div>
      <div className="mt-3 proceed">
        <BlueButton text="PROCEED TO PAYPAL" />
      </div>
      {/* FRANCHISE PAYMENT OPTION , AMOUNT & FRANCHISE CITY */}
      <div className="mt-2">
        <BlueButton onClick={showModal} text="modal1" />
        <BlueButton onClick={showModal1} text="modal2" />
      </div>

      {/* INSUFFICIENT TOKEN BALANCE */}
      <Modal
        title="Basic Modal"
        className="draftcommon"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[<BlueButton onClick={handleCancel} text="DONE" />]}
        centered>
        <InsufficientTokenModal />
      </Modal>

      {/* INSUFFICIENT ETHEREUM BALANCE */}
      <Modal
        title="Basic Modal"
        className="draftcommon"
        open={isModalVisible1}
        onOk={handleOk1}
        onCancel={handleCancel1}
        footer={[<BlueButton onClick={handleCancel1} text="DONE" />]}
        centered>
        <InsufficientEthereumModal />
      </Modal>
    </>
  );
};

export default MintPlayerPayment;
