/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./PurchaseFranchiseStyle.scss";
import PurchaseFranchiseContent from "./Common/PurchaseFranchiseContent";
import WalletConnected from "./WalletConnected/WalletConnected";
import { Container, Row, Col } from "react-bootstrap";
import CardBody from "../Common/Card/CardBody";
import Card from "../Common/Card/Card";
import { Divider } from "antd";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import FranchiseSalesModal from "./Modal/FranchiseSalesModal";
import LoadingScreen from "../Common/LoadingScreen/LoadingScreen";
import OwnFranchiseHeader from "../OwnFranchise/OwnFranchiseHeader";
import { getSetting } from "redux/actions/settingAction";
import { InfoModal } from "./Modal/InfoModal";
import { InfoCircleFilled } from "@ant-design/icons";
import { cleanCityInfo } from "redux/actions/TeamAction";

const PurchaseFranchiseMain = () => {
  const dispatch = useDispatch();
  const setting = useSelector((state: RootState) => state.setting.setting);
  const acquireFranchiseLoader = useSelector(
    (state: RootState) => state.app.acquireFranchiseLoader
  );
  const inviteCredits = useSelector(
    (state: RootState) => state.app.inviteCredits
  );
  const userTeamList = useSelector(
    (state: RootState) => state?.team?.userTeamsList
);


  // Franchise Sales
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  const handleOk2 = () => {
    setIsModalVisible2(false);
    createCookie();
  };
  const handleCancel2 = () => {
    setIsModalVisible2(false);
    createCookie();
  };

  const handleCloseInviteCreditsModal = () => {
    dispatch({ type: "SET_INVITE_CREDITS", payload: null });
  };

  const createCookie = () => {
    const date = new Date();
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
    const expires = "; expires=" + date.toUTCString();

    document.cookie = "showpopup= value" + expires + "; path=/";
  };

  useEffect(() => {
    if (userTeamList?.length === 0) {
      setIsModalVisible2(true);
    }
  }, [userTeamList?.length]);

  useEffect(() => {
    dispatch(getSetting());
    dispatch(cleanCityInfo());
  }, []);

  const currentDate = new Date().toJSON().slice(0, 10);
  const from = new Date(setting?.openDate);
  const to = new Date(setting?.closeDate);
  const check = new Date(currentDate);

  const DateCheck = check >= from && check <= to;
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);

  return (
    <>
      {acquireFranchiseLoader && <LoadingScreen />}
      <Container>
        <OwnFranchiseHeader />
        <div className="mb-5 purfranchisemain">
          <Card>
            <CardBody>
            <h4 className="mb-4">
              CUSTOMIZE & CREATE YOUR TEAM <InfoCircleFilled onClick={() => setIsInfoModalVisible(true)}/>
            </h4>
              <Row>
                <Col xl={7} lg={7}>
                  {DateCheck ? (
                    <PurchaseFranchiseContent />
                  ) : (
                    <h4>
                      Sorry, the franchise sales are currently closed. Please try again later.
                    </h4>
                  )}
                </Col>
                <Col className="verticaldivider">
                  <Divider type="vertical" />
                </Col>
                <Col xl={4} lg={4}>
                  <WalletConnected walletconnected payment />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Container>

      <Modal
        title="Basic Modal"
        className="draftcommon franchisesales"
        open={!!inviteCredits}
        // onOk={handleOk2}
        onCancel={handleCloseInviteCreditsModal}
        centered
      >
        <div className="startdraftmain">
          <p>
            You have been invited to join the GM Dynasty community. As a welcome
            gift, you have been given {inviteCredits} Franchise Credits to
            acquire a franchise.
          </p>
        </div>
      </Modal>

      <Modal
        title="Basic Modal"
        className="draftcommon franchisesales w-75-vw"
        open={isModalVisible2}
        onOk={handleOk2}
        onCancel={handleCancel2}
        centered
      >
        <FranchiseSalesModal />
      </Modal>
      <InfoModal 
        open={isInfoModalVisible}
        onClose={() => setIsInfoModalVisible(false)}
      />
    </>
  );
};

export default PurchaseFranchiseMain;
