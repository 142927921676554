import Error from "../../assets/images/common/error.svg";

const InsufficientEthereumModal = () =>{
    return(
        <>
            <div className="startdraftmain" >
                <img src={Error} alt="erroricon" />
                <p>You have an insufficient Ethereum balance to complete the
                transaction. Use your connected wallet to acquire more
                Ethereum to continue with this payment transaction.</p>
            </div> 
        </>
    );
}

export default InsufficientEthereumModal;