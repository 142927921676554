/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import OverviewLeagueResults from "./OverviewLeagueResults";
import Card from "../../../Common/Card/Card";
import CardBody from "../../../Common/Card/CardBody";
import { Row, Col } from "react-bootstrap";
import OverviewLeagueLeaders from "../../../LeagueStanding/TabDetail/OverviewTab/OverviewLeagueLeaders";

const OverviewTabMain: React.FC = () => (
  <Row>
    <Col xl={12} className="mb-5">
      <Card>
        <CardBody>
          <OverviewLeagueLeaders />
          <OverviewLeagueResults />
        </CardBody>
      </Card>
    </Col>
  </Row>
);

export default OverviewTabMain;
