import { API_URL } from 'config';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import './RenderHelmet.scss';

type Props = {
  helmet?: number | string;
  logo?: number | string;
  mirror?: boolean;
  size?: number;
  onClick?: () => void;
};

export const RenderHelmet: React.FC<Props> = ({ helmet, logo, mirror, size, onClick }) => {
  const avData = useSelector((state: RootState) => state.team.avatar);

  const helmetURL = 
  typeof helmet === 'string' 
    ? helmet 
    : avData?.helmet?.find((h: { id: number }) => h.id === helmet)?.url;
  const logoURL = 
    typeof logo === 'string' 
      ? logo 
      : avData?.logo?.find((h: { id: number }) => h.id === logo)?.url;

  if (!helmetURL || !logoURL) {
    return <div />;
  }

  let styles: React.HTMLAttributes<HTMLDivElement>['style'] = { backgroundImage: `url(${API_URL}${helmetURL})` };
  if (mirror) {
    styles = { ...styles, transform: 'scaleX(-1)' };
  }
  if (size) {
    styles = { ...styles, width: size, height: size * 1889 / 1500 };
  }
  if (onClick) {
    styles = { ...styles, cursor: 'pointer' };
  }

  return (
    <div className="render-helmet-container" style={styles} onClick={onClick}>
      <img className="render-logo" src={`${API_URL}${logoURL.replace("/logo", "/logo/full")}`} alt="helmetsmall" />
    </div>
  );
};
