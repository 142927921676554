import WithLoginNavbar from "components/layout/WithLoginNavbar/WithLoginNavbar";
import { ProfileMain } from "components/Profile";

export function ProfileScreen() {
   return (
      <div className="pagelayout">
         <WithLoginNavbar />
         <ProfileMain />
      </div>
   );
}
