import { get } from "../../../../../redux/services/Api";

export const getGroupGamesPlayedNum = async(groupId?: number) => {
  if (!groupId) return "N/A";
  try {
    const data = await get<{groupGamesPlayed: number}>(
      `/engine/games/groups/${groupId}/gamesPlayed`,
    );
    return data.groupGamesPlayed;
  } catch (e) {
    console.log(e);
    return "N/A";
  }
}
