import { Form, Input, Switch } from "antd";
import { Col, Row } from "react-bootstrap";
import { GroupAvatarUpload } from "./GroupAvatarUpload";
import { useGroupForm } from "./hooks/useGroupForm";
import { useParams } from "react-router-dom";

export function GroupForm() {
  const { groupId } = useParams<{ groupId: string }>();
  const { form, handleUploadGroupAvatar, groupAvatarFile, onFinish } =
    useGroupForm(Number(groupId));

  return (
    <Form
      form={form}
      name="createGroup"
      onFinish={onFinish}
      className="create-group__form"
      initialValues={{
        name: "",
        description: "",
        manualApprove: false,
        leaquaCreationAll: false,
      }}
    >
      <Form.Item
        name="name"
        rules={[
          { required: true, message: "Please input your group name!" },
          { max: 20, message: "Group name cannot exceed 20 characters" },
        ]}
      >
        <Input placeholder="Enter group name" />
      </Form.Item>

      <Form.Item
        name="description"
        rules={[
          { required: false, message: "Please input your group description!" },
          {
            max: 300,
            message: "Group description cannot exceed 300 characters",
          },
        ]}
      >
        <Input.TextArea placeholder="Enter group description" />
      </Form.Item>
      <GroupAvatarUpload
        handleUploadAvatar={handleUploadGroupAvatar}
        file={groupAvatarFile}
      />
      <div className="create-group__form__settings">
        <p className="title">Settings</p>
        <Form.Item
          label="manual approve all joins"
          name="manualApprove"
          className="create-group__form__settings-switch"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        <Form.Item
          label="allow any member to create a league"
          name="leaquaCreationAll"
          className="create-group__form__settings-switch"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </div>
      <Row className="justify-content-center">
        <Col xs={12} md={4}>
          <button className="blueButton" type="submit">
            SAVE
          </button>
        </Col>
      </Row>
    </Form>
  );
}
