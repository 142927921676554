import Card from "../Common/Card/Card"
import CardBody from "../Common/Card/CardBody";
import Title from "../Common/Title/Title";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { calculateRounds, getRoundName, mapSeasonScores } from "helper/helper";
import { getEliminationScores } from "redux/actions/GameEngineAction";
import Dropdown from "components/Common/Form/Dropdown/Dropdown";
import LeagueScoreList from "components/Common/LeagueScoreTab/LeagueScoreList";
import { RootState } from "redux/store";

type Props = {
  eliminationId?: number;
}

export const EliminationScoreTab: React.FC<Props> = ({ eliminationId }) => {
  const dispatch = useDispatch();
  const [round, setRound] = useState(1);

  const gameSeasonScores = useSelector(
    (state: RootState) => state.gameEngine.gameSeasonScores
  );

  const eliminationOverview = useSelector(
    (state: RootState) => state.gameEngine.eliminationOverview
  );

  const data1 = useMemo(() => gameSeasonScores?.data ? mapSeasonScores(gameSeasonScores.data) : [], [gameSeasonScores])

  useEffect(() => {
     if (eliminationId) {
        dispatch(getEliminationScores(eliminationId, 0, round));
    }
  }, [eliminationId, round]);

  const possibleRoundsQuantity = eliminationOverview 
    ? calculateRounds(eliminationOverview.tree[0].length)
    : null;

  const possibleRounds = useMemo(() => {
    if (possibleRoundsQuantity && eliminationOverview?.tree) {
      const rounds = [];
      for (let i = 1; i <= eliminationOverview.tree.length; i++) {
        rounds.push({
          id: i,
          option: getRoundName(possibleRoundsQuantity, i),
        });
      }

      if (eliminationOverview?.thirdPlaceGame) {
        rounds.push({
          id: -1,
          option: 'THIRD PLACE GAME',
        })
      }

      return rounds;
    }

    return [];
  }, [eliminationOverview?.thirdPlaceGame, possibleRoundsQuantity, eliminationOverview?.tree]);

  const handleSelectedRoundChange = (e: any) => {
    if (e?.target?.value) {
      const roundID = possibleRounds.find(
        (round: { id: number, option: string | number }) => round.option === e.target.value
      )?.id;

      if (roundID) {
        setRound(roundID);
      }
    }
  };

  return (
    <Card>
      <CardBody>
        <div className="leaguescoredrop">
          <Title text="ROUNDS" />
          <Dropdown 
            dropDownOptions={possibleRounds}
            onChange={handleSelectedRoundChange}
          />
        </div>
        <Title text="LEAGUE SCORES" />
        {data1?.map(d => (
          <LeagueScoreList data1={d} key={d.leagueid} />
        ))}
      </CardBody>
    </Card>
  );
};

