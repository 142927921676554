import "./StatsTabStyle.scss";
import { Row, Col } from "react-bootstrap";
import { Table } from "antd";
import Title from "../../../Common/Title/Title";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { UserRecordAPI } from "../../../../redux/actions/GameEngineAction";
import React, { useEffect, useMemo } from "react";
import {
  eliminationColumns,
  headToheadColumns,
  seasonColumns,
  seasonData,
} from "./constants";
import { useTeamPerformance } from "./hooks/useTeamPerformance";

type Props = {
  engId?: number;
};

const StatsFranchisePerformance: React.FC<Props> = ({ engId }) => {
  const dispatch = useDispatch();

  const teamPerformance = useTeamPerformance(engId);
  
  useEffect(() => {
    if (engId) {
      dispatch(UserRecordAPI(engId));
    }
  }, [engId]);

  const userRecord = useSelector(
    (state: RootState) => state?.gameEngine?.UserRecord
  );

  const h2hStatsData = useMemo(() => {
    return [
      {
        headTohead: "HEAD-TO-HEAD PLAYED :",
        value: userRecord?.won + userRecord?.lost + userRecord?.tied ?? "N/A",
      },
      {
        headTohead: "RECORD :",
        value:
          userRecord?.won && userRecord?.lost && userRecord?.tied === undefined
            ? `N/A`
            : `${userRecord?.won}-${userRecord?.lost}-${userRecord?.tied}`,
      },
      {
        headTohead: "ROI (WINNING-FEES) :",
        value: "N/A",
      },
    ];
  }, [userRecord]);

  const eliminationData = useMemo(() => {
    return [
      {
        elimination: "ELIMINATION PLAYED :",
        value: (typeof teamPerformance?.elimWon === 'number' && typeof teamPerformance?.elimLost === 'number')
          ? teamPerformance?.elimWon + teamPerformance?.elimLost
          : "N/A",
      },
      {
        elimination: "RECORD :",
        value: (typeof teamPerformance?.elimWon === 'number' && typeof teamPerformance?.elimLost === 'number')
          ? `${teamPerformance?.elimWon}-${teamPerformance?.elimLost}-0`
          : "N/A",
      },
      {
        elimination: "FINALS REACHED :",
        value: typeof teamPerformance?.finalsReached === 'number' ? teamPerformance?.finalsReached : "N/A",
      },
      {
        elimination: "CHAMPIONSHIPS :",
        value: typeof teamPerformance?.elimWon === 'number' ? teamPerformance?.elimWon : "N/A",
      },
      {
        elimination: "BEST RESULTS :",
        value: "N/A",
      },
      {
        elimination: "ROI (WINNIG-FEES) :",
        value: "N/A",
      },
    ]
  }, [teamPerformance]);

  return (
    <div className="mb-4">
      <Title text="FRANCHISE PERFORMANCE" />
      <Row>
        <Col xl={4} lg={4}>
          <div className="statsTable standingmain mt-2">
            <Table
              columns={seasonColumns}
              pagination={false}
              dataSource={seasonData.map((data, i) => ({ ...data, key: i }))}
              size="small"
            />
          </div>
        </Col>
        <Col xl={4} lg={4}>
          <div className="statsTable standingmain mt-2">
            <Table
              columns={eliminationColumns}
              pagination={false}
              dataSource={eliminationData.map((data, i) => ({
                ...data,
                key: i,
              }))}
              size="small"
            />
          </div>
        </Col>
        <Col xl={4} lg={4}>
          <div className="statsTable standingmain mt-2">
            <Table
              columns={headToheadColumns}
              pagination={false}
              dataSource={h2hStatsData.map((data, i) => ({
                ...data,
                key: i,
              }))}
              size="small"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default StatsFranchisePerformance;
