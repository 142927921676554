import NavbarWithOutLogin from "../../components/layout/WithOutLoginNavbar/NavbarWithOutLogin";
import MarketMain from "../../components/Market/MarketMain";

const MarketScreen = () =>{
    return(
        <>
            <div className="pagelayout" >
                <NavbarWithOutLogin about />
                <MarketMain />
            </div>
        </>
    );
}

export default MarketScreen;