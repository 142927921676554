import { Col, Row } from 'react-bootstrap';
import { LeagueTeam } from '../../LeagueOverviewElimination/TabDetail/OverviewTab/hooks/types';
import { RenderHelmet } from 'components/Common/RenderHelmet/RenderHelmet';

type Props = {
  team: LeagueTeam;
};

export const LeagueTeamItem: React.FC<Props> = ({ team }) => (
  <Col xl={4} lg={4} md={6} sm={12} xs={12} className="mb-4">
    <div className="league-team">
      <Row>
        <Col xs={4}>
          <div className="league-team-helmet-container">
              <RenderHelmet helmet={team.helmet} logo={team.logo} size={110}/>
          </div>
        </Col>
        <Col xs={6}>
          <div className="league-team-text">
            <span>
              <strong>{team.teamName}</strong>
            </span>
            <span>{team.region}</span>
            <span>{team.userName}</span>
          </div>
        </Col>
        <Col xs={2}>
          <div className="league-team-ovr-container">
            <div className="league-team-ovr">{team.ovr}</div>
          </div>
        </Col>
      </Row>
    </div>
  </Col>
);
