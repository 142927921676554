import { useState } from 'react';
import { get } from 'redux/services/Api';

type Game =  {
  gid: number | null;
  lost: {
    utid: number;
    pts?: number;
  } & HelmetData[0];
  won: {
    utid: number;
    pts?: number;
  } & HelmetData[0];
};

type WeekDetails = {
  weekId: string;
  day_number: number;
  scheduledAt: Date | string;
  H2HGames: Game[];
}[];

type HelmetData = {
  engId: number;
  helmet: string;
  logo: string;
  userName: string;
  teamName: string;
  userId: number;
}[];

export const useWeekDetails = (weekId?: string) => {
  const [weekDetails, setWeekDetails] = useState<WeekDetails | null>(null);
  const [loading, setLoading] = useState(false);

  const loadWeekDetails = async () => {
    if (weekDetails) {
      setWeekDetails(null);
      return;
    }
    try {
      setLoading(true);
      const response = await get<WeekDetails>(`/engine/leagues/seasons/season/week/${weekId}/weekGames`);
      const preparedEngIds = response
        .map((d) => d.H2HGames.map((g) => [g.lost.utid, g.won.utid]))
        .flat()
        .join(',');

      if (preparedEngIds.length) {
        const helmetsData = await get<HelmetData>(`/user/get-full-helmet/${preparedEngIds}`);
        response.forEach((d) => {
          d.H2HGames.forEach((g) => {
            g.lost = {
              ...g.lost,
              ...helmetsData.find((h) => h.engId === g.lost.utid),
            };
            g.won = {
              ...g.won,
              ...helmetsData.find((h) => h.engId === g.won.utid),
            };
          });
        });
      }
      setWeekDetails(response);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return { weekDetails, loadWeekDetails, loading };
};
