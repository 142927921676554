import { Col, Row } from 'react-bootstrap';
import Title from '../../../Common/Title/Title';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { useEffect, useMemo } from 'react';
import { getPerGameLeaders } from '../../../../redux/actions/GameEngineAction';
import { getTeamLeadersForFranchiseOverview } from 'helper/helper';
import LeagueOverviewLeader from 'components/Common/LeagueOverviewTab/LeagueOverviewLeader';

type Props = {
  engId?: number;
  id?: number;
}

const StatsTeamLeader: React.FC<Props> = ({ engId, id }) => {
  const dispatch = useDispatch();

  const teamLeaders = useSelector((state: RootState) => state.gameEngine.perGameLeaders);

  useEffect(() => {
    if (engId) {
      dispatch(getPerGameLeaders(engId));
    }
  }, [engId]);

  const teamLeadersData = useMemo(() => getTeamLeadersForFranchiseOverview(teamLeaders, id, true), [teamLeaders, id]);

  return (
    <div className="mb-4">
      <Title text="ALL TIME TEAM LEADERS" />
      <div className="leaderlistmain">
        <Row>
          {teamLeadersData?.map((d, i) => (
            <Col key={i} xl="4">
              <LeagueOverviewLeader {...d} />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default StatsTeamLeader;
