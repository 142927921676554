/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import './OverviewTabStyle.scss';
import Title from '../../../Common/Title/Title';
import { Link, useParams } from 'react-router-dom';
import { Table } from 'antd';
import { RootState } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { getActiveH2HGamesByTeam } from '../../../../redux/actions/GameEngineAction';
import history from '../../../../routes/history';
import { getFullGameType } from 'helper/helper';
import { EliminationTeamsTooltip } from './EliminationTeamsTooltip';
import { useLastGameResults } from 'components/HistoricalResults/hooks/useLastGameResults';
import { useLastEliminationResults } from 'components/HistoricalResults/hooks/useLastEliminationResults';

const transactionsColumns: any = [
  {
    title: 'PLAYER NAME',
    dataIndex: 'playerName',
    fixed: 'left',
  },
  {
    title: 'DATE',
    dataIndex: 'date',
  },
  {
    title: 'LAST RESULTS',
    dataIndex: 'lastResults',
  },
];

const activeLeaguesColumns: any = [
  {
    title: 'ENTRY TYPE',
    dataIndex: 'entryType',
    fixed: 'left',
  },
  {
    title: 'RECORD',
    dataIndex: 'record',
  },
  {
    title: 'POINTS FOR',
    dataIndex: 'pointsFor',
  },
  {
    title: 'POINTS AGAINST',
    dataIndex: 'pointsAgainsts',
  },
  {
    title: 'OPPONENT(S)',
    dataIndex: 'opponent',
  },
  {
    title: 'GAME ID',
    dataIndex: 'gameId',
  },
  // {
  //   title: "STANDINGS",
  //   dataIndex: "standings",
  // },
  // {
  //   title: "LAST RESULTS",
  //   dataIndex: "lastResults",
  // },
];

const completedGamesColumns: any = [
  {
    title: 'ENTRY TYPE',
    dataIndex: 'entryType',
    fixed: 'left',
  },
  {
    title: 'DATE',
    dataIndex: 'date',
  },
  {
    title: 'RESULT',
    dataIndex: 'result',
  },
  {
    title: 'OPPONENT(S)',
    dataIndex: 'opponent',
  },
  {
    title: 'GAME ID',
    dataIndex: 'gameId',
  },
];

const OverviewLeagueResults: React.FC = () => {
  const dispatch = useDispatch();
  const franchiseData: { id: number } = history.location.state as { id: number };
  let { franchiseId }: any = useParams();
  if (!franchiseId) {
    franchiseId = franchiseData?.id;
  }

  const userTeamListData = useSelector((state: RootState) => state.team.userTeamsList);

  const activeH2hGames: any[] = useSelector((state: RootState) => state.gameEngine.inProgressH2HGamesByTeam);

  const franchise = useMemo(() => {
    return userTeamListData?.find((info: any) => info?.id == franchiseId);
  }, [userTeamListData, franchiseId]);

  useEffect(() => {
    if (franchise?.engId) {
      dispatch(getActiveH2HGamesByTeam(franchise?.engId));
    }
  }, [franchise?.engId]);

  const { lastGameResults } = useLastGameResults(franchise?.engId, 3);
  const { lastEliminationResults } = useLastEliminationResults(franchise?.engId, 3);
  const allGames = [...(lastGameResults?.allGames || []), ...(lastEliminationResults?.allGames || [])]
    .filter(Boolean)
    .sort((a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp));
  const allTeamNames = { ...(lastGameResults?.teamNames || {}), ...(lastEliminationResults?.teamNames || {}) };

  const lastGamesData = useMemo(() => {
    return allGames?.map((game) => {
      const won = game?.type === "H" ? game?.won : null;
      const lost = game?.type === "H" ? game?.lost : null;

      const gameResult =
        game?.type === 'H'
          ? (won?.utid === franchise?.engId ? 'W' : 'L') + `: ${won?.pts} TO ${lost?.pts}`
          : game?.type === 'E'
          ? `${allTeamNames[game?.lastRound?.[0]?.winner as number]} WON`
          : 'N/A';

      const opponentTeams: any = Object.values(game?.teams)?.filter((t: any) => t !== franchise?.engId);
      const opponent =
        game?.type === 'E' ? (
          <EliminationTeamsTooltip text={opponentTeams.map((teamId: number) => allTeamNames[teamId]).join(', ')}>
            {`${opponentTeams.length} TEAMS`}
          </EliminationTeamsTooltip>
        ) : game?.type === 'H' ? (
          allTeamNames[opponentTeams[0]]
        ) : (
          'N/A'
        );

      return {
        key: game.gid,
        entryType: getFullGameType(game.type),
        date: new Date(game?.timestamp).toLocaleDateString() || 'N/A',
        gameId: game?.gid ? (
          game?.type === 'H' ? (
            <Link to={`/game-results/${game?.gid}?tab=DRIVE-CHART`} className="seasonLink">
              {game?.gid}
            </Link>
          ) : game.type === 'E' ? (
            <Link to={`/leagueelimination?gid=${game?.gid}`} className="seasonLink">
              {game?.gid}
            </Link>
          ) : (
            'N/A'
          )
        ) : (
          'N/A'
        ),
        result: gameResult,
        opponent,
      };
    });
  }, [lastGameResults, franchise]);

  const activeGamesData = useMemo(() => {
    return activeH2hGames?.map((game) => {
      const opponentTeam: any = Object.values(game?.teams)?.find((t: any) => t?.utid !== franchise?.engId);
      const opponentTeams: any = Object.values(game?.teams)?.filter((t: any) => t !== franchise?.engId);
      const opponentName =
        game.type === 'H' ? (
          opponentTeam?.teamName || opponentTeam?.name || 'N/A'
        ) : game.type === 'E' ? (
          <EliminationTeamsTooltip text={opponentTeams.map((teamId: number) => game?.teamNames[teamId]).join(', ')}>
            {`${opponentTeams.length} TEAMS`}
          </EliminationTeamsTooltip>
        ) : (
          'N/A'
        );

      return {
        key: game.gid,
        entryType: getFullGameType(game.type),
        record: game.type === 'H' ? 'N/A' : '',
        pointsFor: game.type === 'H' ? 'N/A' : '',
        pointsAgainsts: game.type === 'H' ? 'N/A' : '',
        opponent: opponentName,
        gameId:
          game.type === 'H' ? (
            <Link to={`/gameplay?id=${game?.gid}`} className="seasonLink">
              {game?.gid}
            </Link>
          ) : game.type === 'E' ? (
            <Link to={`/leagueelimination?gid=${game?.gid}`} className="seasonLink">
              {game?.gid}
            </Link>
          ) : (
            'N/A'
          ),
      };
    });
  }, [activeH2hGames]);

  return (
    <>
      <div className="standingmain">
        <Title text="ACTIVE GAMES" />
        <Table
          columns={activeLeaguesColumns}
          pagination={false}
          dataSource={activeGamesData}
          size="small"
          // scroll={{ x: 1500 }}
        />
      </div>
      <div className="standingmain">
        <Title text="COMPLETED GAMES" />
        <Table columns={completedGamesColumns} pagination={false} dataSource={lastGamesData} size="small" />
      </div>

      <div className="standingmain">
        <Title text="TRANSACTIONS" />
        <Table
          columns={transactionsColumns}
          pagination={false}
          // ToDo: dataSource={}
          size="small"
          // scroll={{ x: 1500 }}
        />
      </div>
    </>
  );
};

export default OverviewLeagueResults;
