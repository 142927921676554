import "./FranchiseDetailStyle.scss";
import Card from "../Common/Card/Card";
import CardBody from "../Common/Card/CardBody";
import { Container } from "react-bootstrap";
import FranchiseCareerRating from "./FranchiseCareerRating";
import StatsTeamLeader from "../FranchiseOverview/TabDetail/StatsTab/StatsTeamLeader";
import StatsFranchisePerformance from "../FranchiseOverview/TabDetail/StatsTab/StatsFranchisePerformance";
import AnotherTeamHeader from "../FullTeamStat/AnotherTeamHeader";
import { useParams } from "react-router-dom";

const FranchiseDetailMain: React.FC = () => {
  const { franchiseId }: any = useParams();

  return (
    <Container className="pb-5">
      <AnotherTeamHeader/>
      <Card>
        <CardBody>
          <StatsFranchisePerformance engId={+franchiseId}/>
          <StatsTeamLeader engId={+franchiseId}/>
          <FranchiseCareerRating />
        </CardBody>
      </Card>
    </Container>
  );
};

export default FranchiseDetailMain;
