import { useState } from "react";

export enum ProgressionTimeline {
  ONE_MONTH = "one_month",
  THREE_MONTHS = "three_months",
  SIX_MONTHS = "six_months",
  LAST_TEN_GAMES = "last_ten_games",
  LAST_TWENTY_GAMES = "last_twenty_games",
  LAST_THIRTY_GAMES = "last_thirty_games",
}

type SelectOption = { id: number; option: string; value: ProgressionTimeline };

export function useProgressionDropdown() {
  const [selectedTimeline, setSelectedTimeline] = useState<ProgressionTimeline>(
    ProgressionTimeline.ONE_MONTH
  );
  const handleSelectTimeline = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value;

    setSelectedTimeline(value as ProgressionTimeline);
  };

  const timelineOptions: SelectOption[] = [
    {
      id: 1,
      value: ProgressionTimeline.LAST_TEN_GAMES,
      option: "Last 10 games",
    },
    {
      id: 2,
      value: ProgressionTimeline.LAST_TWENTY_GAMES,
      option: "Last 20 games",
    },
    {
      id: 3,
      value: ProgressionTimeline.LAST_THIRTY_GAMES,
      option: "Last 30 games",
    },
    { id: 4, value: ProgressionTimeline.ONE_MONTH, option: "One months" },
    { id: 5, value: ProgressionTimeline.THREE_MONTHS, option: "Three months" },
    { id: 6, value: ProgressionTimeline.SIX_MONTHS, option: "Six months" },
  ];
  return { selectedTimeline, handleSelectTimeline, timelineOptions };
}
