import { Row, Col } from "react-bootstrap";
//import purchaseFranchise from "../../assets/images/wallet/selectCity.jpg";
import mint from "../../assets/images/common/mint_player.png";
import MintDropdown from "./MintDropdown";

const MintContent = () =>{
    return(
        <>
            <Row>
                <Col xl={3} lg={3} md={3} sm={3} >
                    <div className="leagueimage" >
                        <img src={mint} alt="cardimage" className="mb-3" />
                    </div>
                </Col>
                <Col xl={9} lg={9} md={9} sm={9} >
                    <div className="leaguecontent" >
                        <h5>MINT AGAME ASSEST</h5>
                        <div className="performancepaycontent mintcontent" >
                            <p>Minting is the process in which your game asset (player, coach, gm) are
                            converted into nfts (non-fungible tokens). Each minted nft is unique 
                            and has one-of-a-kinds traits. </p>
                            <p>Once minted, the NFT is owned by you and can be held in your connected
                            wallet for game use or sold on OpenSea. You also have limited commercial
                            right to use the image. See terms for full details</p>
                        </div>
                        <div className="performancepaycontent termsservice" >
                            <p>Important Minting Rules :</p>
                            <ul>
                                <li>Once minted the games asset traits will be locked and no longer impacted
                                by game play.</li>
                                <li>Minting can’t be reversed. It is up to you to evaluate your game assets 
                                performance and pick the best time to lock-in the traits.</li>
                                <li>Minting can take up to 3 hours upon completing the transaction. The NFT will
                                automatically be placed in your connected wallet.</li>
                                <li>Pricing is based on asset rating.</li>
                            </ul>
                        </div>
                        <MintDropdown />
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default MintContent;