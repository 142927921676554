import LeagueDetail from "../Common/LeagueDetail/LeagueDetail";
import Profile from "../../assets/images/common/userName.png";

const MintHeader = () =>{
    const data1 = [
        {
            id: 1,
            profileimage:  Profile,
            protfolioname: "'PORTFOLIO NAME' FRANCHISES",
            ownertitle: "USER NAME",
            ownername: "DAVE BURGER",
            currentleaguetitle: "NO. OF FRANCHISES : ",
            currentleague: "0",
            foundedtitle: "EMAIL",
            founded: "info@gmail.com",
            foundplaytitle: "MINTED PLAYER",
            foundplay: "0",
            citytitle: "JOINED",
            city: "8/22/1990",
            mintedplayertitle: "CURRENT LEAGUE : ",
            mintedplayer: "0",
            wallettitle: "WALLET",
            walletdigit: "NOT CONNECTED",
            fundstitle: "FUNDS - IN - PLAY",
            fundsdigit: "0",
            teamrating: "TOTAL WINS",
            teamratingdigit: "120",
            fanbase: "WIN %",
            fanbasedigit: "45 %",
            winning: "TITLES",
            winningdigit: "3",
        }
    ]
    return(
        <>
            {data1.map((d:any,i:any) => (
                <LeagueDetail teamrating data={d} />
            ))}
        </>
    );
}

export default MintHeader;