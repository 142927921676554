import Card from "components/Common/Card/Card";
import CardBody from "components/Common/Card/CardBody";
import Dropdown from "components/Common/Form/Dropdown/Dropdown";
import { Col, Row } from "react-bootstrap";
import {
  ProgressionTimeline,
  useProgressionDropdown,
} from "../hooks/useProgressionDropdown";
import PlayerCareerRating from "../PlayerCareerRating";
import { AdvancedProgressionStats } from "../AdvancedProgressionStats";
import { useMemo } from "react";
import moment from "moment";
import { getAverageRatingIncrement } from "../utils/getAverageRatingIncrement";

export function ProgressionTab({
  playerMappedRatingHistory,
  rankings,
  position,
}: {
  playerMappedRatingHistory: any[];
  rankings?: {
    currentRanking: number;
    highestRanking: number;
    lowestRanking: number;
  };
  position?: string;
}) {
  const { selectedTimeline, timelineOptions, handleSelectTimeline } =
    useProgressionDropdown();
  const ratingsHistory = useMemo(
    () =>
      playerMappedRatingHistory.filter((record, i, arr) => {
        const recordDate = moment(record.date);
        const currentDate = moment();
        if (selectedTimeline === ProgressionTimeline.ONE_MONTH) {
          return recordDate.isAfter(currentDate.subtract(1, "month"));
        }
        if (selectedTimeline === ProgressionTimeline.THREE_MONTHS) {
          return recordDate.isAfter(currentDate.subtract(3, "month"));
        }
        if (selectedTimeline === ProgressionTimeline.SIX_MONTHS) {
          return recordDate.isAfter(currentDate.subtract(6, "month"));
        }
        if (selectedTimeline === ProgressionTimeline.LAST_TEN_GAMES) {
          return i >= arr.length - 10;
        }
        if (selectedTimeline === ProgressionTimeline.LAST_TWENTY_GAMES) {
          return i >= arr.length - 20;
        }
        if (selectedTimeline === ProgressionTimeline.LAST_THIRTY_GAMES) {
          return i >= arr.length - 30;
        }
        return true;
      }),
    [playerMappedRatingHistory, selectedTimeline]
  );
  const ratingHighPoint = Math.max(
    ...ratingsHistory.map((record) => record?.value)
  );
  const ratingLowPoint = Math.min(
    ...ratingsHistory.map((record) => record?.value)
  );
  const averageIncrease = getAverageRatingIncrement(ratingsHistory);

  if (!Boolean(playerMappedRatingHistory?.length)) {
    return (
      <Card>
        <CardBody>
          <div className="playerDetailTab">
            <div className="tab__section">
              <h5 className="tab__section_heading">NO RATING HISTORY</h5>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
  return (
    <Card>
      <CardBody>
        <div className="playerDetailTab">
          <div className="tab__section">
            <h5 className="tab__section_heading">PLAYER RATING PROGRESSION</h5>
            <Row className="franchiseDropdown rating-range__selector">
              <Col xl={4} lg={4}>
                <Dropdown
                  value={selectedTimeline}
                  dropDownOptions={timelineOptions}
                  onChange={handleSelectTimeline}
                />
              </Col>
            </Row>
            <PlayerCareerRating ratingHistory={ratingsHistory} />
          </div>
          <div className="tab__section">
            <h5 className="tab__section_heading">PROGRESSION ADVANCED STATS</h5>
            <AdvancedProgressionStats
              rankings={rankings}
              position={position}
              ratingHighPoint={ratingHighPoint}
              ratingLowPoint={ratingLowPoint}
              averageRatingIncrease={averageIncrease}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
