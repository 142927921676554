import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import FranchiseOverviewMain from "./../../components/FranchiseOverview/FranchiseOverviewMain";

const FranchiseOverviewScreen: React.FC = () => (
  <div className="pagelayout">
    <WithLoginNavbar />
    <FranchiseOverviewMain />
  </div>
);

export default FranchiseOverviewScreen;
