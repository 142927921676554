/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Card from "../Common/Card/Card";
import CardBody from "../Common/Card/CardBody";
import Title from "../Common/Title/Title";
import LeagueAvailableList from "./LeagueAvailableList";
import { useInvitedGames } from "helper/useInvitedGames";
import { useActiveGamesDetails } from "./hooks/useActiveGamesDetails";

const LeagueEnteredView: React.FC = () => {
  const userId = useSelector(
    (state: RootState) => state?.app?.resData?.id
  );
  const games = useActiveGamesDetails(userId);
  const { invitedGames } = useInvitedGames(userId);

  if (!games?.length && !invitedGames?.length) return null;

  return (
    <div className="mb-3">
      <Card>
        <CardBody>
          {!!games?.length && (
            <div className="mb-4">
              <Title text="LEAGUES ENTERED" />
              {games.map((d: any, i: number) => (
                <LeagueAvailableList
                  key={i}
                  data={{ ...d, isGameJoined: true }}
                />
              ))}
            </div>
          )}
          {!!invitedGames?.length && (
            <>
              <Title text="LEAGUE INVITES" />
              {invitedGames
                ?.map((d: any) => ({
                  ...d,
                  teamrating: `${d?.ratingRangeMin} TO ${d?.ratingRangeMax}`,
                }))
                ?.map((d: any, i: any) => (
                  <LeagueAvailableList key={i} data={d} />
                ))}
            </>
            )}
        </CardBody>
      </Card>
    </div>
  );
};

export default LeagueEnteredView;
