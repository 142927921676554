import Card from "../Common/Card/Card";
import CardBody from "../Common/Card/CardBody";
import { Container, Row, Col } from "react-bootstrap";
import "./ContactStyle.scss";
import ContactDetail from "./ContactDetail";
import ContactForm from "./ContactForm";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import LoadingScreen from "./../Common/LoadingScreen/LoadingScreen";

const ContactMain = () => {
  const appStateLoader = useSelector((state: RootState) => state.app.loader);

  return (
    <>
      {!!appStateLoader && <LoadingScreen />}
      <div className="contact-main">
        <Container>
          <Card>
            <CardBody>
              <div className="contact_head">
                <h5>CONTACT US</h5>
                <p>QUESTIONS, FEEDBACK, PRAISE? WE WANT TO HEAR FROM YOU!</p>
              </div>
              <Row>
                <Col md={4}>
                  <ContactDetail />
                </Col>
                <Col md={8}>
                  <ContactForm />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default ContactMain;
