/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./LeaguePaymentStyle.scss";
import { Container, Row, Col } from "react-bootstrap";
import CardBody from "../Common/Card/CardBody";
import Card from "../Common/Card/Card";
import { Divider } from "antd";
import LeaguePaymentContent from "./LeaguePaymentContent/LeaguePaymentContent";
import WalletConnected from "../PurchaseFranchise/WalletConnected/WalletConnected";
import history from "../../routes/history";
import FranchiseHeader from "../Common/FranchiseHeader/FranchiseHeader";

const LeaguePaymentMain: React.FC = () => {

  const leagueData: any = [history.location.state];

  const viewPaymentComponent = () => {
    const isPaymentCompopentVisible = (
      leagueData 
      && leagueData[0] 
      && (leagueData[0].leagueprice !== null || leagueData[0].leagueprice !== undefined)
      && leagueData[0].gid 
      && leagueData[0].franchise.engId
      && leagueData[0].franchise.teamName
    );

    if (isPaymentCompopentVisible) {
      return (
        <WalletConnected
          walletconnected
          payment
          data={{
            leagueprice: leagueData[0].leagueprice,
            gid: leagueData[0].gid,
            engId: leagueData[0].franchise.engId,
            teamName: leagueData[0].franchise.teamName,
            isInvitation: leagueData[0].isInvitation,
            JOINCODE: leagueData[0]?.firstUserJoincode || leagueData[0].JOINCODE,
            leagueType: leagueData[0]?.type
          }}
        />
      );
    }

    return null;
  };

  return (
    <Container>
      <FranchiseHeader franchise={leagueData?.[0]?.franchise} />
      <div className="leaguepaymentmain mb-4">
        <Card>
          <CardBody>
            <Row>
              <Col xl={7}>
                <LeaguePaymentContent leaguedata={leagueData} />
              </Col>
              <Col className="verticaldivider">
                <Divider type="vertical" />
              </Col>
              <Col xl={4}>
                {viewPaymentComponent()}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

export default LeaguePaymentMain;
