import Card from "../../../Common/Card/Card";
import CardBody from "../../../Common/Card/CardBody";
import ScheduleDiagram from "./ScheduleDiagram";
import { useEliminationOverview } from "./hooks/useEliminationOverview";
import winner from "../../../../assets/images/leagueElimination/winnercup.svg";
import thirdPlaceWinner from "../../../../assets/images/leagueElimination/3dplacewinnercup.svg";
import { CountdownTimer } from "components/Common/Countdown/CountdownTimer";
import { useEffect, useState } from "react";
import { RenderTrophy } from "components/Common/RenderTrophy/RenderTrophy";

type Props = {
  gid: number;
};

const ScheduleTabMain: React.FC<Props> = ({ gid }) => {
  const [eliminationSceduleHeader, setEliminationScheduleHeader] = useState<
    string | React.ReactElement
  >("");

  const eliminationData = useEliminationOverview(+gid);
  const thirdPlaceGame =
    eliminationData && eliminationData.thirdPlaceGame
      ? [eliminationData.thirdPlaceGame]
      : [];

  const [winnerImage, setWinnerImage] = useState<string | React.ReactElement>(winner);

  useEffect(() => {
    if (eliminationData?.startAt) {
      const diff =
        Date.parse(new Date(eliminationData.startAt).toString()) - Date.now();
      if (diff > 0) {
        if (diff < 1800000) {
          setEliminationScheduleHeader(
            <>
              <span>GAME WILL START IN </span>
              <CountdownTimer targetDate={new Date(eliminationData?.startAt)} />
            </>
          );
        } else {
          setEliminationScheduleHeader(
            <>
              <span>GAME WILL START AT </span>
              {new Date(eliminationData?.startAt).toLocaleString()}
            </>
          );
        }
        const i = setTimeout(() => {
          setEliminationScheduleHeader("ELIMINATION SCHEDULE");
        }, diff);

        return () => {
          clearTimeout(i);
        };
      } else {
        setEliminationScheduleHeader("ELIMINATION SCHEDULE");
      }
    }
  }, [eliminationData?.startAt]);

  useEffect(() => {
    if (eliminationData?.trophyId) {
      setWinnerImage(
        <RenderTrophy 
          trophyId={eliminationData.trophyId}
          mode="cup"
          style={{ width: "100%", height: "160px" }}
        />
      );
    }
  }, [eliminationData?.trophyId]);

  return (
    <Card>
      <CardBody>
        <div className="elimination-schedule-tab-container">
          <h4 className="elimination-schedule-header">
            {eliminationSceduleHeader}
          </h4>
          {eliminationData &&
          eliminationData.tree &&
          !!eliminationData.tree.length ? (
            <ScheduleDiagram
              tree={eliminationData.tree}
              teamNames={eliminationData.teamNames}
              winnerText="WINNER"
              cupImage={winnerImage}
              helmets={eliminationData.helmets}
            />
          ) : (
            <p>Loading Tournament</p>
          )}
          {eliminationData && eliminationData.thirdPlaceGame && (
            <div className="third-place-game-tree">
              <h4 className="elimination-schedule-header">
                GAME FOR THIRD PLACE
              </h4>
              <ScheduleDiagram
                tree={[thirdPlaceGame]}
                teamNames={eliminationData.teamNames}
                winnerText="THIRD PLACE"
                cupImage={thirdPlaceWinner}
                helmets={eliminationData.helmets}
              />
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default ScheduleTabMain;
