/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./MarketStyle.scss";
import { Link } from "react-router-dom";
import close from "../../assets/images/common/close.svg";
import lather from "../../assets/images/market/lather_bucket.png";
import BlueButton from "../Common/Button/BlueButton/BlueButton";

const MarketMain = () => {
  return (
    <>
      <div className="signUpBackground">
        <div className="signUpMain marketmain">
          <Link to="/">
            <img src={close} alt="close" className="close-icon" />
          </Link>
          <div className="marketcontent">
            <img src={lather} alt="lather" />
            <p>
              Leather Bucket Heads are more than your average NFT avatars
              reproducing widely across the metaverse. All Leather Bucket Heads
              owners will receive admittance to the exclusive Locker Room. An
              area within the{" "}
              <a
                href="https://www.leatherbucketheads.com/"
                target="_blank"
                rel="noreferrer">
                www.leatherbucketheads.com
              </a>{" "}
              that allows our community to hang-out, share and even show-off.
            </p>
            <p>
              Most importantly, Leather Bucket Heads was created to be part of
              GM Dynasty. Each Leather Bucket Heads NFT grants you the right to
              mint a player for GM Dynasty. Your Old School Leather Bucket Head
              becomes an actual player in this new sports ecosystem.
            </p>
            <a
              href="https://www.diversifygames.com/market/"
              target="_blank"
              rel="noreferrer">
              <BlueButton text="GET A LEATHER BUCKET HEAD" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketMain;
