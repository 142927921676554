/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import LeagueOverviewLeader from '../../../Common/LeagueOverviewTab/LeagueOverviewLeader';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { useParams } from 'react-router';
import { useEffect, useMemo } from 'react';
import { getPerGameLeaders } from '../../../../redux/actions/GameEngineAction';
import history from '../../../../routes/history';
import { getTeamLeadersForFranchiseOverview } from 'helper/helper';

const OverviewLeagueLeaders: React.FC = () => {
  const dispatch = useDispatch();
  const franchiseData: { id: number } = history.location.state as { id: number };
  let { franchiseId }: any = useParams();
  if (!franchiseId) {
    franchiseId = franchiseData?.id;
  }

  const userTeamListData = useSelector((state: RootState) => state.team.userTeamsList);

  const teamLeaders = useSelector((state: RootState) => state.gameEngine.perGameLeaders);

  const franchise = useMemo(() => {
    return userTeamListData?.find((info: any) => info?.id == franchiseId || info?.engId == franchiseId);
  }, [userTeamListData, franchiseId]);

  useEffect(() => {
    if (franchise && franchise?.engId) {
      dispatch(getPerGameLeaders(franchise?.engId));
    }
  }, [franchise]);

  const teamLeaderData = useMemo(() => getTeamLeadersForFranchiseOverview(teamLeaders, franchiseId), [teamLeaders]);

  return (
    <>
      <h5 className="title">TEAM LEADERS</h5>
      <Row>
        {teamLeaderData?.map((d: any, i: any) => (
          <Col xl={4} key={i}>
            <LeagueOverviewLeader {...d} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default OverviewLeagueLeaders;
