/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Container, Row, Col } from "react-bootstrap";
import MintHeader from "./MintHeader";
import CardBody from "../Common/Card/CardBody";
import Card from "../Common/Card/Card";
import "./MintPlayerStyle.scss";
import { Divider } from "antd";
import MintPlayerPayment from "./MintPlayerPayment";
import MintContent from "./MintContent";
import Footer from "../Common/Footer/Footer";

const MinthPlayerMain = () => {
  return (
    <>
      <Container>
        <MintHeader />
        <div className="mintplayermain pb-4">
          <Card>
            <CardBody>
              <Row>
                <Col xl={7}>
                  <MintContent />
                </Col>
                <Col className="verticaldivider">
                  <Divider type="vertical" />
                </Col>
                <Col xl={4}>
                  <MintPlayerPayment />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default MinthPlayerMain;
