import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import Title from "../Title/Title";
import LeagueScoreList from "./LeagueScoreList";
import "./LeagueScoreStyle.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { getGameSeasonScores } from "../../../redux/actions/GameEngineAction";
import { useEffect, useMemo, useState } from "react";
import { mapSeasonScores } from "helper/helper";
import { PaginationPages } from "components/HistoricalResults/PaginationPages";
import Dropdown from "../Form/Dropdown/Dropdown";
import { useParams } from "react-router-dom";

const options = [
  {
    id: 1,
    option: "HEAD-TO-HEAD",
    value: "H",
  },
  {
    id: 2,
    option: "ELIMINATION",
    value: "E",
  },
  {
    id: 3,
    option: "SEASON",
    value: "S",
  },
];

const LeagueScoreTabMain: React.FC = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [type, setType] = useState<"H" | "S" | "E">("H");
  const { franchiseId }: any = useParams();

  const gameSeasonScores = useSelector(
    (state: RootState) => state.gameEngine.gameSeasonScores
  );

  const userTeamList = useSelector(
    (state: RootState) => state.team.userTeamsList
  );

  const total = gameSeasonScores ? Math.ceil(gameSeasonScores.total / gameSeasonScores.pageSize) : 0;
  const data1 = useMemo(() => gameSeasonScores?.data ? mapSeasonScores(gameSeasonScores.data) : [], [gameSeasonScores])

  useEffect(() => {
      const teamId = userTeamList?.find((team) => team.id === +franchiseId)?.engId;
      const teamIds = userTeamList?.map((team) => team.engId);

      if (!teamIds && !teamId) {
        return;
      }

      dispatch(getGameSeasonScores(page, teamId ? [teamId] : teamIds, type));
  }, [page, userTeamList, type, franchiseId]);

  const onPageClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, shift = 0) => {
    setPage(+(e.target as HTMLElement).innerText + shift || 1)
  }

  const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setType(e.target.value as "H" | "S" | "E");
    setPage(1);
  }

  return (
    <Card>
      <CardBody>
        <div className="leaguescoredrop">
          <Title text="SELECT GAME TYPE" className="mb-4"/>
          <Dropdown 
            dropDownOptions={options}
            value={type}
            onChange={handleTypeChange}
          />
        </div>
        <Title text="LEAGUE SCORES" className="mb-4"/>
        {data1?.map(d => (
          <LeagueScoreList data1={d} key={d.leagueid} />
        ))}
        <PaginationPages 
          onPageClick={onPageClick}
          page={page}
          nextDisabled={total === 0 || page === total}
          handleNextPage={() => setPage(page + 1)}
          handlePrevPage={() => setPage(page - 1)}
        />
      </CardBody>
    </Card>
  );
};

export default LeagueScoreTabMain;
