import { OrderTypes } from "components/PurchaseFranchise/WalletConnected/WalletConnected";
import { post, get } from "../services/Api";
import {
  ACQUIRE_FRANCHISE_FAIL,
  ACQUIRE_FRANCHISE_REQUEST,
  ACQUIRE_FRANCHISE_SUCCESS,
  CLEAN_FORGOT_ERROR,
  CLEAN_SUCCESS,
  CREATE_PAYOUT_FAIL,
  CREATE_PAYOUT_REQUEST,
  CREATE_PAYOUT_SUCCESS,
  GET_FEE_RATES_FAIL,
  GET_FEE_RATES_REQUEST,
  GET_FEE_RATES_SUCCESS,
  SAVE_PAYMENT_DATA_FAIL,
  SAVE_PAYMENT_DATA_REQUEST,
  SAVE_PAYMENT_DATA_SUCCESS,
  UPDATE_WALLET_BALANCE,
  UPDATE_PROMO_BALANCE,
  CHECK_IS_PROMO_USED_REQUEST,
  CHECK_IS_PROMO_USED_SUCCESS,
  CHECK_IS_PROMO_USED_FAIL,
  GET_MIN_WITHDRAWAL_AMOUNT_REQUEST,
  GET_MIN_WITHDRAWAL_AMOUNT_SUCCESS,
  GET_MIN_WITHDRAWAL_AMOUNT_FAIL,
  GET_INVITE_PROMO_AMOUNTS_REQUEST,
  GET_INVITE_PROMO_AMOUNTS_SUCCESS,
  GET_HELMETS_REQUEST,
  GET_HELMETS_FAIL,
  GET_HELMETS_SUCCESS,
} from "./types";
import { InvitePromoCreditsAmounts } from "redux/reducers/AppReducer";
import { PreviewData } from "components/PurchaseFranchise/Common/Preview";
import { HelmetData } from "react-helmet-async";

export interface PaymentData {
  userId: number;
  amount: string;
  fee: string;
  emailAddress: string;
  oderId: string;
  partnerCode?: string;
  paymentType: "PAYPAL" | "CRYPTO" | "CREDITCARD";
  status: string;
  transactionId: string;
  orderType: "BALANCE" | OrderTypes;
}

export interface CreatePayoutData {
  amount: number;
  recipientEmail: string;
  userId: number;
}

export interface AcquireFranchiseData {
  userId: number;
  userName: string;
  city: string;
  partnerCode: string;
  promoCredits: number;
  franchiseData: PreviewData & { teamName: string };
}

export const savePaymentData = (data: PaymentData) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: SAVE_PAYMENT_DATA_REQUEST,
        payload: {},
      });
      const res: any = await post("/user/savePayment", data);
      dispatch({
        type: SAVE_PAYMENT_DATA_SUCCESS,
        payload: { message: res.message },
      });
    } catch (error: any) {
      dispatch({
        type: SAVE_PAYMENT_DATA_FAIL,
        error: error,
      });
    }
  };
};

export const cleanSavePaymentData = () => {
  return async (dispatch: any, getState: any) => {
    dispatch({
      type: SAVE_PAYMENT_DATA_SUCCESS,
      payload: { message: null },
    });
  };
};

export const createPayout = (data: CreatePayoutData) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: CREATE_PAYOUT_REQUEST,
        payload: {},
      });
      const res: any = await post("/user/createPayout", data);
      dispatch({
        type: CREATE_PAYOUT_SUCCESS,
        payload: { message: res.message },
      });
    } catch (error: any) {
      dispatch({
        type: CREATE_PAYOUT_FAIL,
        error: error,
      });
    }
  };
};

export const cleanCreatePayout = () => {
  return async (dispatch: any, getState: any) => {
    dispatch({
      type: CREATE_PAYOUT_SUCCESS,
      payload: { message: null },
    });
  };
};

export const acquireFranchise = (data: AcquireFranchiseData) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: ACQUIRE_FRANCHISE_REQUEST,
        payload: {},
      });
      const res: any = await post("/user/acquireFranchise", data);
      dispatch({
        type: ACQUIRE_FRANCHISE_SUCCESS,
        payload: { franchiseId: res.message },
      });
    } catch (error: any) {
      dispatch({
        type: ACQUIRE_FRANCHISE_FAIL,
        error: error,
      });
    }
  };
};

export const cleanAcquireFranchise = () => {
  return async (dispatch: any, getState: any) => {
    dispatch({
      type: ACQUIRE_FRANCHISE_SUCCESS,
      payload: { franchiseId: null },
    });
  };
};

export const cleanForgotError = () => {
  return async (dispatch: any) => {
    dispatch({
      type: CLEAN_FORGOT_ERROR,
    });
  };
};
export const cleanSuccess = () => {
  return async (dispatch: any) => {
    dispatch({
      type: CLEAN_SUCCESS,
    });
  };
};

export const getFeeRates = (qb?: () => void) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: GET_FEE_RATES_REQUEST,
        payload: {},
      });
      const res: any = await get("/get-fee-rates");
      dispatch({
        type: GET_FEE_RATES_SUCCESS,
        payload: { feeRates: res },
      });
    } catch (error: any) {
      dispatch({
        type: GET_FEE_RATES_FAIL,
        error: error,
      });
    } finally {
      qb && qb();
    }
  };
};

export const updateWalletBalance = (walletBalance: number) => {
  return async (dispatch: any, getState: any) => {
    dispatch({
      type: UPDATE_WALLET_BALANCE,
      payload: { walletBalance: walletBalance.toFixed(2) },
    });
  };
};

export const updatePromoBalance = (walletBalance: number) => {
  return async (dispatch: any, getState: any) => {
    dispatch({
      type: UPDATE_PROMO_BALANCE,
      payload: { walletBalance },
    });
  };
};

export const checkIsPromoUsed = (userId: string | number) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: CHECK_IS_PROMO_USED_REQUEST,
        payload: {},
      });
      const response = await get(`/user/check-promo-status?userId=${userId}`);
      dispatch({
        type: CHECK_IS_PROMO_USED_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: CHECK_IS_PROMO_USED_FAIL,
        error: error,
      });
    }
  };
};

export const getMinWithdrawalAmount = () => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: GET_MIN_WITHDRAWAL_AMOUNT_REQUEST,
        payload: {},
      });
      const res = (await get("/get-min-withdrawal-amount")) as {
        MIN_WITHDRAWAL_AMOUNT: number;
      };
      dispatch({
        type: GET_MIN_WITHDRAWAL_AMOUNT_SUCCESS,
        payload: { minWithdrawalAmount: res.MIN_WITHDRAWAL_AMOUNT },
      });
    } catch (error: any) {
      dispatch({
        type: GET_MIN_WITHDRAWAL_AMOUNT_FAIL,
        error,
      });
    }
  };
};

export const getInviteCreditsPromoAmounts = () => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: GET_INVITE_PROMO_AMOUNTS_REQUEST,
        payload: {},
      });
      const res = (await get(
        "/user/get-invite-promo-amounts"
      )) as InvitePromoCreditsAmounts;
      dispatch({
        type: GET_INVITE_PROMO_AMOUNTS_SUCCESS,
        payload: { ...res },
      });
    } catch (error: any) {
      dispatch({
        type: GET_INVITE_PROMO_AMOUNTS_SUCCESS,
        error,
      });
    }
  };
};

export const getHelmets = (engIds: number[]) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: GET_HELMETS_REQUEST });
      const response = await get<HelmetData[]>(
        `/user/get-full-helmet/${engIds.join(",")}`
      );

      dispatch({
        type: GET_HELMETS_SUCCESS,
        payload: response,
      });
    } catch(e) {
      dispatch({
        type: GET_HELMETS_FAIL,
      });
    }
  };
};

