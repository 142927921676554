import { Dispatch } from "redux";
import { get } from "redux/services/Api";
import { Profile } from "types";
import {
  ActionTypes,
  GetProfileFailAction,
  GetProfileRequestAction,
  GetProfileSuccessAction,
  ProfileActionTypes,
} from "./action.types";

export * from "./Friends";

export const GetProfile = (id: number) => {
  return async (dispatch: Dispatch<ProfileActionTypes>) => {
    try {
      dispatch<GetProfileRequestAction>({
        type: ActionTypes.GET_PROFILE_REQUEST,
      });

      const response = await get<{ profile: Profile }>(
        `/profile/${id}`,
      );
      const numberOfTournametsData: number = await (async () => {
        try {
          const tourneysPlayedResponse = await get<{numberOfTournaments: number}>(
            `/engine/owner/${id}/tourneysPlayed`,
          );
          return tourneysPlayedResponse.numberOfTournaments;
        } catch (e) {
          console.log("Error getting number of tournaments played", e);
          return 0;
        }
      })();

      dispatch<GetProfileSuccessAction>({
        type: ActionTypes.GET_PROFILE_SUCCESS,
        payload: {
          profile: {
            ...response.profile,
            championships: numberOfTournametsData,
          },
        },
      });
    } catch (error) {
      if (error instanceof Error) {
        dispatch<GetProfileFailAction>({
          type: ActionTypes.GET_PROFILE_FAIL,
          error: error.message,
        });
      }
      dispatch<GetProfileFailAction>({
        type: ActionTypes.GET_PROFILE_FAIL,
        error: "Something went wrong",
      });
    }
  };
};
