import { TablePaginationConfig } from "antd";

export function hasPagination<T>(
   pageSize: number,
   data: T[],
   size: "default" | "small" = "default"
): TablePaginationConfig | false {
   if (pageSize >= data.length) return false;
   return { pageSize, size };
}
