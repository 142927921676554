/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useMemo } from "react";
import { Table, Modal, Select } from "antd";
import BlueButton from "../../Common/Button/BlueButton/BlueButton";
import TransactionSlipModal from "./TransactionSlipModal";
import { useDispatch } from "react-redux";
// import { RootState } from "../../../redux/store";
import { getTranscations } from "../../../redux/actions/AuthAction";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { hasPagination } from "../utils/hasPagination";

enum FilterTypeEnum {
   DEFAULT = "default",
   PURCHASE_FRANCHASE = "purchaseFranchise",
   LEAGUE_PAYMENT = "leaguePayment",
   MINTING_PAYMENT = "mintingPayment",
   WITHDRAWALS = "withdrawals",
}

enum FilterStatusEnum {
   DEFAULT = "default",
   COMPLETED = "COMPLETED",
   PENDING = "PENDING",
   CANCELLED = "CANCELLED",
}

type TransactionRow = {
   key: number;
   transaction: JSX.Element;
   description: string;
   date: string;
   amount: string;
   fee: string;
   status: string;
};

const { Option } = Select;

// I will provide types soon
const SettingTransaction = ({
   resData,
   allTransactions,
   isLoading,
}: {
   resData: any;
   allTransactions: any[] | null;
   isLoading: boolean;
}) => {
   const [receiptData, setReceiptData] = useState<{}>();

   const [filterType, setFilterType] = useState<FilterTypeEnum>(FilterTypeEnum.DEFAULT);
   const [filterStatus, setFilterStatus] = useState<FilterStatusEnum>(FilterStatusEnum.DEFAULT);

   const dispatch = useDispatch();

   const [isModalVisible1, setIsModalVisible1] = useState(false);
   const showModal1 = (transaction: any) => {
      setReceiptData(transaction);
      setIsModalVisible1(true);
   };
   const handleOk1 = () => {
      setIsModalVisible1(false);
   };
   const handleCancel1 = () => {
      setIsModalVisible1(false);
   };

   const columns: ColumnType<TransactionRow>[] = [
      {
         title: "TRANSACTION ID",
         dataIndex: "transaction",
         key: "transaction",
      },
      {
         title: "DATE",
         dataIndex: "date",
         key: "date",
         sorter: dateComparator,
         showSorterTooltip: false,
      },
      {
         title: "DESCRIPTION",
         dataIndex: "description",
         key: "description",
         sorter: textComparator,
         showSorterTooltip: false,
      },
      {
         title: "AMOUNT",
         key: "amount",
         dataIndex: "amount",
         sorter: numberComparator,
         showSorterTooltip: false,
      },
      {
         title: "FEE",
         key: "fee",
         dataIndex: "fee",
         sorter: numberComparator,
         showSorterTooltip: false,
      },
      {
         title: "STATUS",
         key: "status",
         dataIndex: "status",
         sorter: textComparator,
         showSorterTooltip: false,
      },
   ];

   const transactions = useMemo(() => {
      if (!allTransactions) return [];
      const filteredTransactions = allTransactions
         .filter((transaction: any) => {
            if (filterType === FilterTypeEnum.DEFAULT) return true;
            return transaction?.type === filterType;
         })
         .filter((transaction: any) => {
            if (filterStatus === FilterStatusEnum.DEFAULT) return true;
            return transaction?.status === filterStatus;
         });

      return filteredTransactions;
   }, [allTransactions, filterType, filterStatus]);

   const rows = useMemo(() => {
      if (!transactions?.length) return [];
      const rows = transactions.map((transaction: any) => ({
         key: transaction?.id,
         transaction: <a onClick={() => showModal1(transaction)}>{transaction.gmdTransactionId}</a>,
         description: transaction.orderType,
         date: moment(transaction.created).format("DD MMMM YYYY, HH:mm"),
         amount: `$ ${transaction.amount}`,
         fee: transaction.fee ? `$ ${transaction.fee}` : "NONE",
         status: transaction.status,
      }));

      return rows;
   }, [transactions]);

   useEffect(() => {
      if (!resData?.id) return;
      dispatch(getTranscations(resData?.id));
   }, [dispatch, resData?.id]);

   return (
      <>
         <div className="settingtransaction">
            <h6>Transactions</h6>
            {/* <div
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "row-reverse",
          }}>
          <Select
            disabled={isLoading}
            defaultValue={FilterTypeEnum.DEFAULT}
            style={{ width: 160 }}
            onChange={value => setFilterType(value as FilterTypeEnum)}>
            <Option value={FilterTypeEnum.DEFAULT}>Sort By Type</Option>
            <Option value={FilterTypeEnum.PURCHASE_FRANCHASE}>Franchise Fees</Option>
            <Option value={FilterTypeEnum.LEAGUE_PAYMENT}>League Fees</Option>
            <Option value={FilterTypeEnum.MINTING_PAYMENT}>Minting Fees</Option>
            <Option value={FilterTypeEnum.WITHDRAWALS}>Withdrawals</Option>
          </Select>
          <Select
            disabled={isLoading}
            defaultValue={FilterStatusEnum.DEFAULT}
            style={{ width: 160, marginRight: "10px" }}
            onChange={value => setFilterStatus(value as FilterStatusEnum)}>
            <Option value={FilterStatusEnum.DEFAULT}>Sort By Status</Option>
            <Option value={FilterStatusEnum.COMPLETED}>Completed</Option>
            <Option value={FilterStatusEnum.PENDING}>Pending</Option>
            <Option value={FilterStatusEnum.CANCELLED}>Cancelled</Option>
          </Select>
        </div> */}
            <Table
               loading={isLoading}
               columns={columns}
               dataSource={rows}
               className="transactiontable"
               pagination={hasPagination(10, rows ?? [])}
            />
         </div>

         {/* TRANSACTION SLIP */}
         <Modal
            title=""
            className="connectnewwallet transactionslip"
            open={isModalVisible1}
            onOk={handleOk1}
            onCancel={handleCancel1}
            footer={[
               <BlueButton onClick={handleCancel1} text="CANCEL" />,
               <BlueButton onClick={handleOk1} text="DONE" />,
            ]}
            centered
         >
            <TransactionSlipModal receiptData={receiptData} resData={resData} />
         </Modal>
         {/* TRANSACTION SLIP */}
      </>
   );
};

function dateComparator(a: TransactionRow, b: TransactionRow) {
   const dateA = new Date(a?.date)?.getTime() ?? null;
   const dateB = new Date(b?.date)?.getTime() ?? null;
   if (!dateA || !dateB) return 0;
   return dateA - dateB;
}
function textComparator(a: TransactionRow, b: TransactionRow) {
   return a.status.localeCompare(b.status);
}
function numberComparator(a: TransactionRow, b: TransactionRow) {
   try {
      const amountA = parseFloat(a.amount.replace("$ ", ""));
      const amountB = parseFloat(b.amount.replace("$ ", ""));
      return amountA - amountB;
   } catch (error) {
      return 0;
   }
}

export default SettingTransaction;
