import { API_URL } from "config";
import { get } from "redux/services/Api";
import types from "../actionTypes";

export function eliminationOverview (gid: number) {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: types.ELIMINATION_OVERVIEW_REQUEST,
        payload: {},
      });
      let res: any = await get(
        `/engine/games/elimination/${gid}/gameOverview`,
      );

      if (res?.teams && res?.teams?.length) {
        const helmetsData: any = await get(
          `${API_URL}/user/get-helmet/${res?.teams}`,
          {},
          {
            "Access-Control-Allow-Origin": "*",
          }
        );
      
        if (helmetsData) {
          res.helmets = helmetsData;
        }
      }

      dispatch({
        type: types.ELIMINATION_OVERVIEW_SUCCESS,
        payload: { eliminationOverview: res },
      });
    } catch (error: any) {
      dispatch({
        type: types.ELIMINATION_OVERVIEW_FAIL,
        error: error?.response?.data?.error,
      });
    }
  };
};
