import FranchiseDetail from "../Common/FranchiseDetail/FranchiseDetail";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { API_URL } from "../../config";
import React, { useEffect, useMemo } from "react";
import Stickers from "../../assets/images/league/leagueImage.jpg";
import { getTeamOverview } from "../../redux/actions/GameEngineAction";
import { useParams } from "react-router-dom";
import { fetchSelectedAvatar } from "redux/actions/TeamAction";
import { UserTeamsList } from "types";

const AnotherTeamHeader: React.FC = () => {
  const dispatch = useDispatch();
  const { franchiseId }: any = useParams();

  const teamOverviewData = useSelector(
    (state: RootState) => state?.gameEngine?.teamOverview
  );
  const HeaderData = useSelector(
    (state: RootState) => state?.gameEngine?.UserRecord
  );
  const avatar: UserTeamsList[0] | null | undefined = useSelector(
    (state: RootState) => state?.team?.fetchedAvatar
  );

  useEffect(() => {
    if (franchiseId) {
      dispatch(getTeamOverview(1, +franchiseId));
      dispatch(fetchSelectedAvatar(+franchiseId));
    }
  }, [franchiseId]);

  const data1 = useMemo(
    () => ({
        id: 1,
        profileimage: avatar
          ? `${API_URL}/images/franchise/userFranchise/${avatar.img}`
          : Stickers,
        protfolioname: avatar?.teamName || "---",
        usertitle: "FRANCHISE :",
        username: avatar?.teamName || "---",
        currentleaguetitle: "RATING :",
        currentleague: HeaderData?.ovr === undefined ? 0 : HeaderData?.ovr,
        joinedtitle: "FOUNDED :",
        joined: avatar ? new Date(avatar?.created).toDateString() : "---",
        wallettitle: "CITY :",
        wallet: teamOverviewData?.region || "---",
        win: "RECORD",
        windigit:
          HeaderData?.won && HeaderData?.lost && HeaderData?.tied === undefined
            ? `0-0-0`
            : `${HeaderData?.won}-${HeaderData?.lost}-${HeaderData?.tied}`,
        winpercentage: "WIN %",
        winpercentagedigit:
          HeaderData?.pct === undefined ? `0 %` : HeaderData?.pct,
        rating: "RATING",
        ownerUserNameLabel: "OWNER",
        ownerUserName: teamOverviewData?.userName,
        ownerUserId: teamOverviewData?.userId,
        ratingdigit: HeaderData?.ovr === undefined ? 0 : HeaderData?.ovr,
        ratingStatus: teamOverviewData?.ratingStatus
          ? teamOverviewData?.ratingStatus
          : "--",
      }),
    [
      HeaderData?.lost,
      HeaderData?.ovr,
      HeaderData?.pct,
      HeaderData?.tied,
      HeaderData?.won,
      avatar,
      teamOverviewData?.ratingStatus,
      teamOverviewData?.region,
      teamOverviewData?.userId,
      teamOverviewData?.userName,
    ]
  );

  return (
      <FranchiseDetail rating arrow data={data1} />
  );
};

export default AnotherTeamHeader;
