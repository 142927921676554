import { Link } from "react-router-dom";
import "./LinksStyle.scss";

const Links = () => {
  return (
    <>
      <div className="linkmain">
        <div className="linksection">
          <h5 className="">HOME SCREEN , SIGNUP</h5>
          <Link to="/" target="_blank">
            Slide No. 1 Home Page
          </Link>{" "}
          <br />
          <Link to="/signup" target="_blank">
            Slide No. 2 & 3 Create Account
          </Link>{" "}
          <br />
          <Link to="/signup" target="_blank">
            Slide No. 4 Verify Mail Modal
          </Link>{" "}
          <br />
        </div>
        <div className="linksection">
          <h5 className="">LOGIN</h5>
          <Link to="/signin" target="_blank">
            Slide No. 5 Login
          </Link>{" "}
          <br />
        </div>
        <div className="linksection">
          <h5 className="">FORGOT PASSWORD</h5>
          <Link to="/signin" target="_blank">
            Slide No. 6 Forgot Password
          </Link>{" "}
          <br />
          <Link to="/newpassword" target="_blank">
            Slide No. 7 Reset Password
          </Link>{" "}
          <br />
        </div>
        <div className="linksection">
          <h5 className="">REQUIRED LOGIN INFORMATION</h5>
          <Link to="/signup" target="_blank">
            New Slide Required Login Info
          </Link>{" "}
          <br />
          <Link to="/signup" target="_blank">
            Required Login Info Modal OnClick Submit
          </Link>{" "}
          <br />
        </div>
        <div className="linksection">
          <h5 className="">NO FRANCHIES</h5>
          <Link to="/nofranchise" target="_blank">
            Slide 8: Portfolio of Franchises (no franchises)
          </Link>{" "}
          <br />
        </div>
        <div className="linksection">
          <h5 className="">OWN FRANCHIES</h5>
          <Link to="/ownfranchise" target="_blank">
            Slide 9: Portfolio of Franchises (franchises owned)
          </Link>{" "}
          <br />
        </div>
        <div className="linksection">
          <h5 className="">PURCHASE FRANCHIES</h5>
          <Link to="/purchasefranchise" target="_blank">
            Slide 10: Acquire franchise {">"} Wallet Not Connected{" "}
          </Link>{" "}
          <br />
          <Link to="/purchasefranchise" target="_blank">
            Slide 10.1: Acquire franchise {">"} Wallet Not Connected
            &nbsp;&nbsp;&nbsp;<span>COMMENT OPEN</span>{" "}
          </Link>{" "}
          <br />
          <Link to="/purchasefranchise" target="_blank">
            Slide 11: Acquire franchise {">"} Wallet Connected{" "}
            <span>COMMENT OPEN</span>{" "}
          </Link>{" "}
          <br />
          <Link to="/purchasefranchise" target="_blank">
            Slide 12: Acquire franchise {">"} Paypal Payment{" "}
            <span>COMMENT OPEN</span>{" "}
          </Link>{" "}
          <br />
          <Link to="/purchasefranchise" target="_blank">
            Slide 13: Acquire franchise {">"} Ethreum Payment & Partner Code{" "}
          </Link>{" "}
          <br />
        </div>
        <div className="linksection">
          <h5 className="">IMPORT FRANCHIES</h5>
          <Link to="/purchasefranchise" target="_blank">
            Slide No: 14 Transfer/Import a Franchise (Wallet Not Connected)
          </Link>{" "}
          <br />
          <Link to="/purchasefranchise" target="_blank">
            Slide No: 14.1 Transfer/Import a Franchise (Wallet Connected)
            &nbsp;&nbsp;&nbsp;<span>COMMENT OPEN</span>{" "}
          </Link>{" "}
          <br />
        </div>
        <div className="linksection">
          <h5 className="">FRANCHIES OVERVIEW PAGE</h5>
          <Link to="/franchiseoverview" target="_blank">
            Slide No: 15 Franchise Overview Page
          </Link>{" "}
          <br />
          <Link to="/franchiseoverview" target="_blank">
            Slide No: 16 Roster
          </Link>{" "}
          <br />
          <Link to="/franchiseoverview" target="_blank">
            Slide No: 17 Depth Chart
          </Link>{" "}
          <br />
          <Link to="/franchiseoverview" target="_blank">
            Slide No: 20 Front Office
          </Link>{" "}
          <br />
          <Link to="/franchiseoverview" target="_blank">
            Slide No: 21 Stats (owner of franchise view)
          </Link>{" "}
          <br />
        </div>
        <div className="linksection">
          <h5 className="">FRANCHISE DETAIL</h5>
          <Link to="/franchisedetail" target="_blank">
            Slide No: 22 Franchise Detail
          </Link>{" "}
          <br />
        </div>
        <div className="linksection">
          <h5 className="">PLAYER DETAIL</h5>
          <Link to="/playerdetail" target="_blank">
            Slide No: 22 Player Detail
          </Link>{" "}
          <br />
        </div>
        <div className="linksection">
          <h5 className="">PLAYER DETAIL</h5>
          <Link to="/league" target="_blank">
            Slide No: 23 Games (enter leagues)
          </Link>{" "}
          <br />
        </div>
        <div className="linksection">
          <Link to="/stand" target="_blank">
            Slide No: 24 League Overview (Season View)
          </Link>{" "}
          <br />
          <Link to="/leagueelimination" target="_blank">
            Slide No: 25 League Overview (Elimination view)
          </Link>{" "}
          <br />
          <Link to="/leagueheadtohead" target="_blank">
            Slide No: 26 League Overview (head-to-head view)
          </Link>{" "}
          <br />
          <Link to="/stand" target="_blank">
            Slide No : 27 League standings (division view) – 1
          </Link>{" "}
          <br />
          <Link to="/stand" target="_blank">
            Slide No : 28 League standings (conference view)
          </Link>{" "}
          <br />
          <Link to="/stand" target="_blank">
            Slide No : 29: League scores
          </Link>{" "}
          <br />
          <Link to="/boxscore" target="_blank">
            Slide No : 30: League scores {">"} Box Scores{" "}
          </Link>{" "}
          <br />
          <Link to="/teamstat" target="_blank">
            Slide No : 31: League scores {">"} Team stats{" "}
          </Link>{" "}
          <br />
          <Link to="/drivechart" target="_blank">
            Slide No : 32: League scores {">"} Drive Chart
          </Link>{" "}
          <br />
          <Link to="/stand" target="_blank">
            Slide No : 33: Schedule (Season) & Slide No : 34 Schedule
            (Playoff)(season){" "}
          </Link>{" "}
          <br />
          <Link to="/scheduleelimination" target="_blank">
            Slide 36: League Schedule for game to be played (only for
            elimination and season)
          </Link>{" "}
          <br />
          <Link to="/defensivedepth" target="_blank">
            Slide 38: Depth Chart
          </Link>{" "}
          <br />
          <Link to="/fullteamstat" target="_blank">
            Slide 39: Team stats
          </Link>{" "}
          <br />
          <Link to="/fullplayerstat" target="_blank">
            Slide 40: Player stats
          </Link>{" "}
          <br />
          <Link to="/draft" target="_blank">
            Slide 41: Draft
          </Link>{" "}
          <br />
          <Link to="/draftresult" target="_blank">
            Slide 42: Draft Results
          </Link>{" "}
          <br />
          <Link to="/customize-franchise" target="_blank">
            Slide No. 67 Acquire Franchise {">"} Connected wallet {">"} partner
            code – 2
          </Link>{" "}
          <br />
        </div>
        <div className="linksection">
          <h5 className="">EMAIL TEMPLATE</h5>
          <a
            href="https://thepicab.com/nft/verifyemailaddress/"
            target="_blank"
            rel="noreferrer"
          >
            01.) Verify Email Address
          </a>{" "}
          <br />
          <a
            href="https://thepicab.com/nft/reminderemailaddress/"
            target="_blank"
            rel="noreferrer"
          >
            02.) Reminder Email Address
          </a>{" "}
          <br />
          <a
            href="https://thepicab.com/nft/passwordresetrequest/"
            target="_blank"
            rel="noreferrer"
          >
            03.) Password Reset Request
          </a>{" "}
          <br />
          <a
            href="https://thepicab.com/nft/passwordreset/"
            target="_blank"
            rel="noreferrer"
          >
            04.) Password Reset
          </a>{" "}
          <br />
          <a
            href="https://thepicab.com/nft/passwordreset/"
            target="_blank"
            rel="noreferrer"
          >
            05.) Setting Updated
          </a>{" "}
          <br />
          <a
            href="https://thepicab.com/nft/transactionreceipt/"
            target="_blank"
            rel="noreferrer"
          >
            06.) Transaction Receipt
          </a>{" "}
          <br />
          <a
            href="https://thepicab.com/nft/yourleaguehasbeenended/"
            target="_blank"
            rel="noreferrer"
          >
            07.) Your League Has Been Ended
          </a>{" "}
          <br />
          <a
            href="https://thepicab.com/nft/youreliminationhasbeenended/"
            target="_blank"
            rel="noreferrer"
          >
            08.) Your Elimination Has Been Ended
          </a>{" "}
          <br />
          <a
            href="https://thepicab.com/nft/yourleaguehasbeenended/"
            target="_blank"
            rel="noreferrer"
          >
            09.) Your Head to Head Match Ended
          </a>{" "}
          <br />
          <a
            href="https://thepicab.com/nft/franchiseNFTrejected/"
            target="_blank"
            rel="noreferrer"
          >
            10.) Franchise NFT Has Been Rejected
          </a>{" "}
          <br />
          <a
            href="https://thepicab.com/nft/invitetojoinleague/"
            target="_blank"
            rel="noreferrer"
          >
            11.) Invite To Join League
          </a>{" "}
          <br />
          <a
            href="https://thepicab.com/nft/franchiseNFTapproved/"
            target="_blank"
            rel="noreferrer"
          >
            12.) Franchise NFT Has Been Approved
          </a>{" "}
          <br />
        </div>
        <div className="linksection">
          <h5 className="">SPRINT 9</h5>
          <Link to="/league" target="_blank">
            Slide 23: Games (enter leagues)
          </Link>{" "}
          <br />
          <Link to="/leagueheadtohead" target="_blank">
            Slide 26: League Overview (head-to-head view only)
          </Link>{" "}
          <br />
          <Link to="/leagueheadtohead" target="_blank">
            Slide 29: League scores ( 3rd Head to head view)
          </Link>{" "}
          <br />
          <Link to="/boxscore" target="_blank">
            Slide 30: League scores &gt; Box scores ( 3rd Head to head view)
          </Link>{" "}
          <br />
          <Link to="/teamstat" target="_blank">
            Slide 31: League scores &gt; Team stats ( 3rd Head to head view)
          </Link>{" "}
          <br />
          <Link to="/drivechart" target="_blank">
            Slide 32: League scores &gt; Team stats ( 3rd Head to head view)
          </Link>{" "}
          <br />
          <Link to="/gameplanspecific" target="_blank">
            Slide 37: Game Plan for a specific game ( 3rd Head to head view)
          </Link>{" "}
          <br />
          <Link to="/defensivedepth" target="_blank">
            Slide 38: Depth Chart ( 3rd Head to head view)
          </Link>{" "}
          <br />
          <Link to="/fullteamstat" target="_blank">
            Slide 39: Team stats ( 3rd Head to head view)
          </Link>{" "}
          <br />
          <Link to="/fullplayerstat" target="_blank">
            Slide 40: Player stats ( 3rd Head to head view)
          </Link>{" "}
          <br />
        </div>
      </div>
    </>
  );
};

export default Links;
