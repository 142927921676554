export const INIT_APP = "INIT_APP";

export const GETAVATAR_REQUEST = "GETAVATAR_REQUEST";
export const GETAVATAR_SUCCESS = "GETAVATAR_SUCCESS";
export const GETAVATAR_FAIL = "GETAVATAR_FAIL";

export const SAVEAVATARDATA_REQUEST = "SAVEAVATARDATA_REQUEST";
export const SAVEAVATARDATA_SUCCESS = "SAVEAVATARDATA_SUCCESS";
export const SAVEAVATARDATA_FAIL = "SAVEAVATARDATA_FAIL";

export const UPDATEAVATARDATA_REQUEST = "UPDATEAVATARDATA_REQUEST";
export const UPDATEAVATARDATA_SUCCESS = "UPDATEAVATARDATA_SUCCESS";
export const UPDATEAVATARDATA_FAIL = "UPDATEAVATARDATA_FAIL";

export const USERTEAMSLIST_REQUEST = "USERTEAMSLIST_REQUEST";
export const USERTEAMSLIST_SUCCESS = "USERTEAMSLIST_SUCCESS";
export const USERTEAMSLIST_FAIL = "USERTEAMSLIST_FAIL";

export const ADD_USERTEAMLIST = "ADD_USERTEAMLIST";

export const FETCHSELECTEDAVATAR_REQUEST = "FETCHSELECTEDAVATAR_REQUEST";
export const FETCHSELECTEDAVATAR_SUCCESS = "FETCHSELECTEDAVATAR_SUCCESS";
export const FETCHSELECTEDAVATAR_FAIL = "FETCHSELECTEDAVATAR_FAIL";

export const ADD_CITYINFO = "ADD_CITYINFO";
export const CLEAN_CITYINFO = "CLEAN_CITYINFO";

export const GET_PARTNER_CODE_REQUEST = "GET_PARTNER_CODE_REQUEST";
export const GET_PARTNER_CODE_SUCCESS = "GET_PARTNER_CODE_SUCCESS";
export const GET_PARTNER_CODE_FAIL = "GET_PARTNER_CODE_FAIL";

export const PARTNER_CODE_UPDATE_REQUEST = "PARTNER_CODE_UPDATE_REQUEST";
export const PARTNER_CODE_UPDATE_SUCCESS = "PARTNER_CODE_UPDATE_SUCCESS";
export const PARTNER_CODE_UPDATE_FAIL = "PARTNER_CODE_UPDATE_FAIL";

export const USERINFO_REQUEST = "USERINFO_REQUEST";
export const USERINFO_SUCCESS = "USERINFO_SUCCESS";
export const USERINFO_FAIL = "USERINFO_FAIL";

export const GETCITYLIST_REQUEST = "GETCITYLIST_REQUEST";
export const GETCITYLIST_SUCCESS = "GETCITYLIST_SUCCESS";
export const GETCITYLIST_FAIL = "GETCITYLIST_FAIL";

export const GETCITYDATA_REQUEST = "GETCITYDATA_REQUEST";
export const GETCITYDATA_SUCCESS = "GETCITYDATA_SUCCESS";
export const GETCITYDATA_FAIL = "GETCITYDATA_FAIL";

export const GETFRENCHISEDATA_REQUEST = "GETFRENCHISEDATA_REQUEST";
export const GETFRENCHISEDATA_SUCCESS = "GETFRENCHISEDATA_SUCCESS";
export const GETFRENCHISEDATA_FAIL = "GETFRENCHISEDATA_FAIL";
export const GETFRENCHISEDATA_CLEAR = "GETFRENCHISEDATA_CLEAR";

export const GETCITYDETAIL_REQUEST = "GETCITYDETAIL_REQUEST";
export const GETCITYDETAIL_SUCCESS = "GETCITYDETAIL_SUCCESS";
export const GETCITYDETAIL_FAIL = "GETCITYDETAIL_FAIL";

export const LEGENDMAIL_REQUEST = "LEGENDMAIL_REQUEST";
export const LEGENDMAIL_SUCCESS = "LEGENDMAIL_SUCCESS";
export const LEGENDMAIL_FAIL = "LEGENDMAIL_FAIL";

export const EMAILVEIFICATION_REQUEST = "EMAILVEIFICATION_REQUEST";
export const EMAILVEIFICATION_SUCCESS = "EMAILVEIFICATION_SUCCESS";
export const EMAILVEIFICATION_FAIL = "EMAILVEIFICATION_FAIL";

export const RESPONCERESET_REQUEST = "RESPONCERESET_REQUEST";
export const RESPONCERESET_SUCCESS = "RESPONCERESET_SUCCESS";
export const RESPONCERESET_FAIL = "RESPONCERESET_FAIL";

export const PLAYERREVEALAPPROVAL_REQUEST = "PLAYERREVEALAPPROVAL_REQUEST";
export const PLAYERREVEALAPPROVAL_SUCCESS = "PLAYERREVEALAPPROVAL_SUCCESS";
export const PLAYERREVEALAPPROVAL_FAIL = "PLAYERREVEALAPPROVAL_FAIL";

export const GETPREVIEWNFT_REQUEST = "GETPREVIEWNFT_REQUEST";
export const GETPREVIEWNFT_SUCCESS = "GETPREVIEWNFT_SUCCESS";
export const GETPREVIEWNFT_FAIL = "GETPREVIEWNFT_FAIL";

export const ADD_LEAGUE_ENTER_FORM_DATA = "ADD_LEAGUE_ENTER_FORM_DATA";


export const FETCHALLSELECTEDAVATAR_REQUEST = "FETCHALLSELECTEDAVATAR_REQUEST";
export const FETCHALLSELECTEDAVATAR_SUCCESS = "FETCHALLSELECTEDAVATAR_SUCCESS";
export const FETCHALLSELECTEDAVATAR_FAIL = "FETCHALLSELECTEDAVATAR_FAIL";

export const UPDATE_TEAM_STATUS_REQUEST = "UPDATE_TEAM_STATUS_REQUEST";
export const UPDATE_TEAM_STATUS_SUCCESS = "UPDATE_TEAM_STATUS_UPDATE_TEAM_STATUS_SUCCESS";
export const UPDATE_TEAM_STATUS_FAIL = "UPDATE_TEAM_STATUS_FAIL";

export const GET_USER_TEAMS_REQUEST = "GET_USER_TEAMS_REQUEST";
export const GET_USER_TEAMS_SUCCESS = "GET_USER_TEAMS_SUCCESS";
export const GET_USER_TEAMS_FAIL = "GET_USER_TEAMS_FAIL";
