import WithLoginNavbar from "../../components/layout/WithLoginNavbar/WithLoginNavbar";
import MinthPlayerMain from "../../components/MintPlayer/MinthPlayerMain";

const MintingAssetsScreen = () =>{
    return(
        <>
            <div className="pagelayout" >
                <WithLoginNavbar />
                <MinthPlayerMain />
            </div>
        </>
    );
}

export default MintingAssetsScreen;